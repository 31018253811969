import React, { useEffect, useState, useRef } from 'react'
import { PageHeader, Form, Input, Button, Select, Radio, message, Popconfirm } from 'antd'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import { EnterOutlined, PlusOutlined, RightOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { GetCustomerType } from '../../../api/Code'
import { CheckCustomerId, AddCustomer, AddCustomerRemark } from '../../../api/Customer'
// import { useSelector } from 'react-redux'
import CustomCascader from '../../../components/shared/component/CustomCascader'
const { Option } = Select
const { TextArea } = Input

const Add = ({ pageTitle, ContainerWidth }) => {
    const [form] = Form.useForm()
    // let Town = useSelector(state => state.county)
    const [currentStep, setCurrentStep] = useState(0)
    const [customerType, setCustomerType] = useState(null)
    const [successSubmit, setSuccessSubmit] = useState(null)
    const [isValid, setIsValid] = useState(null)
    const [customerId, setCustomerId] = useState("")
    const [remark, setRemark] = useState("")
    let navigate = useNavigate()
    let idRef = useRef()
    useEffect(() => {
        document.title = pageTitle
        GetCustomerType().then(res => {
            if (res !== null) {
                setCustomerType(res.Result)
            }
        })
    }, [])
    const onSubmitForm = (value) => {
        // update_uid
        let type_code = ''
        if (!value.customerType) {
            value.customerType = []
        }
        value.customerType.forEach((type, i) => {
            type_code += i === 0 ? type : `,${type}`
        })
        value.type_code = type_code
        value.county = value.address_county[0]
        value.town = value.address_county[1]
        delete value["customerType"]
        delete value["address_county"]
        let param = ''
        Object.keys(value).forEach((v, i) => {
            param += i === 0 ? `${v}=${value[v]}` : `&${v}=${value[v]}`;
        })
        AddCustomer(param).then(res => {
            if (res.Result.ErrorMsg === '客戶編號重複') {
                setIsValid(false)
            } else {
                res.Result.Data.name = value.name
                setIsValid(null)
                setCurrentStep(1)
                setSuccessSubmit(res.Result.Data)
            }
        })
    }
    return (
        <div className='container' style={ContainerWidth}>
            <PageHeader
                title="新增客戶"
                onBack={() => currentStep === 0 ? window.history.go(-1) : setCurrentStep(currentStep - 1)}
                className="site-page-header"
                breadcrumb={<BreadCrumb />}
            >
            </PageHeader>
            <div className='step_block'>
                <div >
                    <Form
                        form={form}
                        name="basic"
                        layout="vertical"
                        initialValues={{ is_VIP: "0",execution_region_type:'1' }}
                        onFinish={onSubmitForm}
                    // onFinishFailed={(value)=> console.log(value)}
                    >
                        <div style={{ minHeight: "calc(100vh - 240px)" }}>
                            <div className='step_content'>
                                {
                                    currentStep === 0 ? (<><div style={{ display: "flex" }}>
                                        <Form.Item
                                            label="客戶代號"
                                            name="id"
                                            rules={[{ required: true, message: '請輸入客戶代號!' }]}
                                            style={{ position: 'relative' }}
                                            hasFeedback={isValid !== null ? true : false}
                                            validateStatus={isValid === null ? '' : isValid ? 'success' : 'error'}
                                        >
                                            <Input
                                                ref={idRef}
                                                placeholder='請輸入 3 碼編號後點擊右側按鈕檢驗'
                                                onChange={(e) => {
                                                    setIsValid(null)
                                                    setCustomerId(e.target.value)
                                                    form.setFieldsValue({ id: e.target.value })
                                                    setTimeout(() => {
                                                        idRef.current.focus()
                                                    }, 200)
                                                }}
                                                addonAfter={<EnterOutlined style={{ cursor: "pointer" }} onClick={(e) => {
                                                    CheckCustomerId(customerId)
                                                        .then(res => setIsValid(res.Result))
                                                }} />} style={{ width: "304px" }} />
                                            {
                                                isValid !== null && !isValid && (<div style={{ color: '#ff4d4f', position: 'absolute' }}>此客戶代號已存在!</div>)
                                            }
                                        </Form.Item>
                                        <Form.Item
                                            label="客戶名稱"
                                            name="name"
                                            rules={[{ required: true, message: '請輸入客戶名稱！' }]}
                                            style={{ margin: '0 16px' }}
                                        >
                                            <Input allowClear placeholder='請直接輸入客戶名稱' style={{ width: "304px" }} />
                                        </Form.Item>
                                    </div>
                                        <div style={{ display: "flex" }}>
                                            <Form.Item
                                                label="公司統編"
                                                name="tax_id_no"
                                                rules={[{ required: true, message: '請輸入公司統編！' },
                                                {
                                                    pattern:/^[\d]{8}$/,
                                                    message: "請輸入8位數之公司統編",
                                                }]}
                                            >
                                                <Input allowClear placeholder='請直接輸入公司統編' style={{ width: "304px" }} />
                                            </Form.Item>

                                            <Form.Item
                                                label="公司地址"
                                                name="address_county"
                                                style={{ margin: '0 16px' }}
                                                rules={[{ required: true, message: '請輸入公司地址！' }]}
                                            >
                                                <CustomCascader
                                                    style={{ width: "304px" }}
                                                    OnChange={(val) => form.setFieldsValue({ address_county: val })}
                                                />
                                            </Form.Item>
                                            <div>
                                                <div style={{ height: 34 }}></div>
                                                <Form.Item
                                                    label=""
                                                    name="address"
                                                    rules={[{ required: true, message: '請輸入公司地址！' }]}
                                                >
                                                    <Input allowClear placeholder='請直接輸入地址' style={{ width: "304px" }} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <Form.Item
                                            label={<><span>客戶類別</span><span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>(可複選)</span></>}
                                            name="customerType"
                                        >
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                style={{ width: "304px" }}
                                                placeholder="請選擇客戶類別"
                                                defaultValue={[]}
                                            >
                                                {
                                                    customerType !== null && customerType.map(type => (<Option key={type.Code}>{type.Name}</Option>))
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="業務窗口姓名"
                                            name="business_contact_name"
                                        >
                                             <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                        </Form.Item>
                                        <Form.Item
                                            label={"客戶等級"}
                                            name="is_VIP"
                                            rules={[{ required: true, message: '請選擇客戶等級！' }]}
                                        >
                                            <Radio.Group
                                                buttonStyle="solid"
                                                // style={{ margin: "8px 0" }}
                                            >
                                                <Radio.Button value="0">一般客戶</Radio.Button>
                                                <Radio.Button value="1">VIP 客戶</Radio.Button>
                                            </Radio.Group>

                                        </Form.Item>
                                        <Form.Item
                                            label={"執行分區"}
                                            name="execution_region_type"
                                            rules={[{ required: true, message: '請選擇執行分區！' }]}
                                        >
                                            <Radio.Group
                                                buttonStyle="solid"
                                                // style={{ margin: "8px 0 24px 0" }}
                                            >
                                                <Radio.Button value="1">北區</Radio.Button>
                                                <Radio.Button value="2">中區</Radio.Button>
                                                <Radio.Button value="3">全業</Radio.Button>
                                            </Radio.Group>

                                        </Form.Item>
                                        </>) : (
                                        <>
                                            {
                                                successSubmit && (<>
                                                    <div style={{ fontSize: 30 }}>
                                                        客戶
                                                        <span style={{ color: "#1890FF", fontSize: 30 }}> {successSubmit.id} / {successSubmit.name} </span>
                                                        已新增
                                                    </div>
                                                    <div style={{ color: "rgba(0, 0, 0, 0.45)", marginTop: 8 }}>客戶已建立完成，請替該客戶建立人員、據點、計價模組後方可新增訂單</div>
                                                    <TextArea
                                                        placeholder="額外的據點內容說明請寫在此......"
                                                        autoSize={{ minRows: 2, maxRows: 4 }}
                                                        style={{ width: 395, marginTop: 8 }}
                                                        onChange={(e) => setRemark(e.target.value)}
                                                        value={remark}
                                                    />
                                                    <Button
                                                        style={{ display: "block", marginTop: 8 }}
                                                        disabled={remark !== "" ? "" : "disabled"}
                                                        onClick={() => {
                                                            let updated_uid = '00001'
                                                            let param = `row_id=${successSubmit.row_id}&note=${remark}&update_uid=${updated_uid}`
                                                            AddCustomerRemark(param).then(data => {
                                                                if (data.Result.Success) {
                                                                    setRemark("")
                                                                    form.resetFields()
                                                                    message.success('已新增備註')
                                                                }
                                                            })
                                                        }}
                                                    > 新增備註 </Button>

                                                    <div style={{ marginTop: 32 }}>
                                                        <Button
                                                            type="primary"
                                                            icon={<PlusOutlined />}
                                                            onClick={() => setCurrentStep(0)}
                                                        >新增其他客戶</Button>
                                                        <Button
                                                            icon={<RightOutlined />}
                                                            style={{ margin: "0 8px" }}
                                                            onClick={() => navigate(`/customer/lists/${successSubmit.row_id}`)}
                                                        >客戶內容</Button>
                                                        <Button
                                                            icon={<UnorderedListOutlined />}
                                                            onClick={() => navigate("/customer/lists/")}
                                                        >回列表</Button>
                                                    </div>
                                                </>)
                                            }
                                        </>)
                                }
                            </div>
                        </div>
                        {
                            currentStep === 0 && (<div style={{ background: "white", marginTop: 16, width: "100%", padding: "20px", display: "flex", justifyContent: "flex-end" }}>
                                <Popconfirm
                                    style={{ width: 410 }}
                                    placement="top"
                                    title={
                                        <div>
                                            <div>
                                                <strong>新增還沒完成喔</strong>
                                            </div>
                                            <div style={{ fontSize: 14 }}>
                                                剛剛輸入的所有資訊將不被保存。
                                            </div>
                                        </div>
                                    }
                                    onCancel={() => navigate(-1)}
                                    okText="不，我要留下"
                                    cancelText="對，我要離開"
                                >
                                    <Button type="text" style={{ marginRight: 24 }}>
                                        關閉
                                    </Button>
                                </Popconfirm>
                                <Button type="primary" icon={<PlusOutlined />}
                                    htmlType="submit"
                                > 新增 </Button>
                            </div>)
                        }
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default Add;
