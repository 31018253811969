import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "../../components/shared/BreadCrumb";
import ModalCard from "../../components/cases/case/ModalCard";
import CustomCard from "../../components/shared/component/Card";
import ManualDispatch from "../../components/cases/case/ManualDispatch";
import CaseClosure from "../../components/cases/case/CaseClosure";
import CaseEdit from "../../components/cases/case/CaseEdit";
import TechnicianList from "../../components/cases/case/TechnicianList"
import CarList from "../../components/cases/case/CarList"
import NonOfficalDriver from "../../components/cases/case/NonOfficalDriver"
import History from "../../components/cases/case/History";
import { GetExecutionInfo } from "../../api/Execution";
import moment from 'moment'
import { useSelector } from 'react-redux'
import { GetCaseInfo, CancelAllCases, CancelCases, complaintCase, CasePriceId,CloseCasePriceList,CloseCasePriceLists, ManualCloseCase, GetCaseHistory,dispatchCancelCase,GetCustomerStation, CustomerServiceNote,Continue } from '../../api/Case'
import { GetCaseType } from '../../api/Code'
// import { ContinueExecution } from "../../api/Execution";
import { PageHeader, Button, Tag, Card, Radio, Modal, Form, Select, DatePicker, TimePicker, Input, message, AutoComplete } from "antd";
import { ExclamationCircleOutlined, PhoneOutlined, RightOutlined,FileImageOutlined,VideoCameraOutlined,EditOutlined } from "@ant-design/icons"
import LoadingBlock from "../../components/shared/LoadingBlock";
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { GetServicePointDriverList, GetServicePointList } from "../../api/ServicePoint"
import { RouteName } from "../../util/route"
import { StatusObj } from "../../util/status";
const { Option } = Select
const { TextArea } = Input
const { confirm } = Modal;
const complain = [{ value: 1, text: "等候時間過久取消" }, { value: 2, text: "遲至" }, { value: 3, text: "車損" }, { value: 4, text: "技師流程有誤" }, { value: 5, text: "客服漏派" }, { value: 0, text: "其他" }]


const Case = ({ ContainerWidth, pageTitle, setLoading }) => {
  let { caseId } = useParams();
;
  const navigate = useNavigate();
  const { state, pathname } = useLocation()
  const routeId = RouteName(pathname)
  const dataDate = state?state.dataDate:""
  const vechicleSn = new URLSearchParams(window.location.search).get("vehicle_sn")
  const vehicle_sn = vechicleSn?vechicleSn:state.vehicle_sn

  const [status, setStatus] = useState(null)
  const [reportedMethod, setReportedMethod] = useState(null)
  const [visible, setVisible] = useState(null);
  const [modalVal, setModalVal] = useState(null);
  const [data, setData] = useState(null);
  const [SelectedCarList, setSelectedCarList] = useState([]);
  const [editData, setEditData] = useState(null)
  const [initData, setInitData] = useState(null)
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [technicianData, setTechnicianData] = useState(null)
  const [caseClosureHistory, setCaseClosureHistory] = useState(null)
  const [AllCarBrand, setAllCarBrand] = useState(null)
  const [complainOther, setComplainOther] = useState(false)
  const [pointList, setPointList] = useState([])
  const [AllPointDriver, setAllPointDriver] = useState([])
  const [pointDriver, setPointDriver] = useState([])
  const [transportType, setTransportType] = useState("")
  const [history, setHistory] = useState(null)
  let Town = useSelector((state) => state.county);
  let CasesList = useSelector(state => state.CasesList)
  let certain_case = useSelector(state => state.CertainCase)
  let DispatchCarBrand = useSelector(state => state.CarBrand)
  let UserInfo = useSelector(state => state.UserInfo)
  let UserPermission = UserInfo ? UserInfo.permission.route : null
  let  editForm = useRef()
  const reasonOption = [
    { label: '聯絡不上', value: '1' },
    { label: '車輛故障', value: '2' },
    { label: '臨時請假', value: '3' },
    { label: '客戶因素', value: '4' },
    { label: '督導調度', value: '5' },
    { label: '其他', value: '6' }
  ]
  const extraService = {
    "010": { key: "auction_no", name: "拍賣單號" },
    "011": { key: "auction_no", name: "拍賣單號" },
    "012": { key: "harbor_area_pass", name: "港務通行證" },
    "013": { key: "car_hood_text", name: "車罩需求" },
    "014": { key: "case_content", name: "運送品項、件數" },
    "015": { key: "case_content", name: "案件說明" },
  }
  const cancelCase = async (modal, CaseId, vehicle_sn) => {
    let fetch
    if (vehicle_sn) {
      fetch = await CancelCases(CaseId, vehicle_sn)
    } else {
      fetch = await CancelAllCases(CaseId)
    }
    if (fetch.Result === "取消成功") {
      fetchAPI()
      message.success(fetch.Result);
    }
    modal.destroy()
  }
  function cancel() {
    let modal = Modal.confirm({
      title: "即將終止部分案件派遣，確定嗎？",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <div> 你可以單獨取消此案件，或取消整張大單（如其他案件已被執行將不受此操作影響</div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 24,
              marginBottom: -24,
            }}
          >
            <Button onClick={() => modal.destroy()}> 取消 </Button>
            <Button style={{ margin: "0 8px" }} onClick={() => cancelCase(modal, caseId)}>全部</Button>
            <Button type="primary" onClick={() => cancelCase(modal, caseId, vehicle_sn)}>
              僅針對此案件
            </Button>
          </div>
        </>
      ),
      okButtonProps: {
        style: { display: "none" },
      },
      cancelButtonProps: {
        style: { display: "none" },
      },
    })
  }
  const repeatedCase = () => {
    // console.log(data)
    // return false
    // console.log(`${data.order_customer}/${data.order_type}`)
    // return false
    // orderCustomer: data.order_customer ? `${data.order_customer}/${data.order_type}` : '',
    navigate(`/cases/add`, {
      state: {
        customer_row_id: data.customer_row_id,
        orderCustomer: data.order_customer ? `${data.order_customer}` : '',
        calling_source: data.calling_source==="技師"?"LINE":data.calling_source,
        calling_person_email: data.calling_person_email,
        calling_person_name: data.calling_person_name,
        calling_person_phone: data.calling_person_name,
        case_type_id: data.case_type_id,
        deliveryTime: data.deliveryTime,
        delivery_person: data.delivery_person,
        delivery_person_phone: data.delivery_person_phone,
        destination: data.destination,
        destination_add: data.destination_add,
        destination_address: data.destination_address,
        destination_county: data.destination_county,
        destination_lat: data.destination_lat,
        destination_lng: data.destination_lng,
        destination_service_point: data.destination_service_point,
        destination_town: data.destination_town,
        destination_floor: data.destination_floor,
        line_group_name: data.line_group_name,
        line_id: data.line_id,
        origin: data.origin,
        origin_add: data.origin_add,
        origin_address: data.origin_address,
        origin_county: data.origin_county,
        origin_floor: data.origin_floor,
        origin_lat: data.origin_lat,
        origin_lng: data.origin_lng,
        origin_service_point: data.origin_service_point,
        origin_town: data.origin_town,
        pick_up_person: data.pick_up_person,
        pick_up_person_phone: data.pick_up_person_phone,
        source: data.source,
        is_VIP: data.is_VIP,
        car_hood_type:data.car_hood_type ==="其他"?"2":data.car_hood_type ==="全鋒代準備"?"1":"0",
        car_hood_note_txt:data.car_hood_note,
        auction_no:data.auction_no,
        components_note:data.case_content
      }
    })
  }
  const callingSource = {
    LINE: ["line_group_name", "line_id"],
    Email: ["calling_person_email", "calling_person_name", "calling_person_phone"],
    "080": ["calling_person_name", "calling_person_phone"],
    "傳真": ["calling_person_name", "calling_person_phone"],
    "現場加派": ["calling_person_name", "calling_person_phone"],
  }

  const continueExecuteCase = async () =>{
    let res = await Continue(data.case_info_row_id,{case_info_row_id:data.case_info_row_id})
    if(res && !res.Error){
      fetchAPI()
    }
  }
  const modalPost = async (val) => {
    let res
    if(modalVal ==="1"){
      val.executionDate = val.executeDate.format("YYYY-MM-DD");
      val.service_point = val.service_point.split(",")[1];
      if(val.driver.indexOf("臨時技師")>-1){
        val.driver = val.driver.split("/")[0]
        val.service_point=''
      }
      res = await ManualCloseCase(caseId, vehicle_sn, val)
      // console.log(val)
    }else if(modalVal ==="2"){
      res = await complaintCase(caseId, data.vehicle_sn, val)

    }else if(modalVal ==="3"){
      if(val.transport_type ==='1'){
        val.transport_date = moment(val.date).format("YYYY-MM-DD")
        val.transport_time_from = moment(val.time[0]).format("HH:mm")
        val.transport_time_to = moment(val.time[1]).format("HH:mm")
      }
      val.case_info_row_id = data.case_info_row_id
      res= await dispatchCancelCase(val)
    }else if(modalVal ==="4"){
      res = await CustomerServiceNote({ case_info_row_id: data.case_info_row_id,
        c_note: val.c_service_self_note,
      })
    }
    if (res && !res.Error) {
      setModalVal(null);
      fetchAPI();
      if(modalVal ==='2') message.success("已成功註記");
    }
  }
  const fetchAPI = async () => {

    let case_type = await GetCaseType()
    case_type = case_type.Result
    let response = await GetCaseInfo(caseId, vehicle_sn)
    if (response.Result !== null) {

      let result = { ...response.Result }
      let history = await GetCaseHistory(result.case_info_row_id)
      if (history && history.Result) {
        setHistory(history.Result)
      }
     
      setStatus(response.Result.case_status)
      setInitData(response.Result);
      setReportedMethod(result.report_way)
      //案件類型
      let filter_case_type = case_type.filter(c => c.Code === result.case_type_id)
      if (filter_case_type.length > 0) {
        result.case_type = `${filter_case_type[0]["Code"]} - ${filter_case_type[0]["Name"]}運送`
      }
      //運送時間
      if (result.transport_type === "指定時間") {
        result.deliveryTime = `${moment(result.transport_date).format("YYYY/MM/DD")} ${moment(result.transport_time_from, 'HH:mm').format("HH:mm")} - ${moment(result.transport_time_to, "HH:mm").format("HH:mm")} `;
      } else {
        result.deliveryTime = `${moment(result.transport_date).format(
          "YYYY/MM/DD"
        )}（${result.transport_type}）`;
      }
      //起運點、迄運點
      let address = ['origin', 'destination']
      for (let i = 0; i < address.length; i++) {
        let add = '', code = []
        let key = address[i].charAt(0).toUpperCase() + address[i].slice(1)
        let service_point = `${address[i]}_service_point`
        let filter_county = Town[0]["children"].filter((t) => t.value === result[`${address[i]}_county`])
        let address_code = ''
        if (result[service_point]) {
          // if (filter_county.length > 0) {
          //   let filter_town = filter_county[0]["children"].filter(
          //     (t) => t.Code === result[`${address[i]}_town`]);
          //     console.log()
          // }
          let point = await GetCustomerStation(result.customer_row_id)
          if (!point.Error) {
            let filter = point.Result.filter((t) => t.code === result[service_point]);
            if (filter.length > 0) {
              add = filter[0]["name"]
              code = [`${result[service_point]},${filter[0]["row_id"]}`]
              result[key] = filter[0]["name"]
            }
          }
        } else {
          if (filter_county.length > 0) {
            let filter_town = filter_county[0]["children"].filter(
              (t) => t.Code === result[`${address[i]}_town`]);
            result[address[i].charAt(0).toUpperCase() + address[i].slice(1)] = `${filter_county[0]["label"]}/${filter_town[0]["label"]}`
            address_code = `${filter_county[0]["label"]}${filter_town[0]["label"]}`
          }
          code = ['1', result[`${address[i]}_county`], result[`${address[i]}_town`]]
          add = `${address_code}${result[`${address[i]}_address`]}`
          if (result[`${address[i]}_floor`]) {
            add += `${result[`${address[i]}_floor`]}樓`
          }
        }
        result[address[i]] = code
        result[`${address[i]}_add`] = add
      }
      //廠牌車型
      if (AllCarBrand !== null && result.vehicle_model_id ) {
        let filter_car_model = AllCarBrand.filter(car => car.Code === result.vehicle_model_id.substring(0, 3))
        if (filter_car_model.length > 0) {
          let filter = filter_car_model[0]["children"].filter(car => car.Code === result.vehicle_model_id)
          result.car_model_text =filter.length>0? `${filter[0]["parentName"]} / ${filter[0]["label"]}`:'';
        } else {
          result.car_model_text = result.vehicle_model_id;
        }
      } else {
        result.car_model_text = result.vehicle_model_id;
      }

      //車輛狀態
      result.carStatus = {
        type: "tag",
        color: result.power_status === 1 ? "green" : "red",
        content: result.power_status === 1 ? ["正常"] : ["不可行駛"],
        status: result.power_status
      };
      // if(result.power_status){
       
      // }else{
      //   result.carStatus = null
      // }
      
      //交車人 、接車人
      let infomation = ["delivery_person", "pick_up_person"]
      infomation.forEach((key, i) => {
        let obj, type = i === 0 ? 'sender' : "receiver"
        if (result[`${key}_phone`] && result[`${key}_phone`] !== "undefined") {
          obj = {
            type: "icons",
            link: "tel",
            label: result[`${key}_phone`],
            title: result[key] !== "undefined" ? result[key] : "",
            icon: <PhoneOutlined />,
          };
        } else {
          obj = result[key] ? result[key] : ""
        }
        result[type] = obj
      })
      //案件來源
      let calling_source_arr = [];
      if (callingSource[result.calling_source]) {
        callingSource[result.calling_source].forEach((s) => {
          if (result[s] && result[s] !== "" && result[s] !== "undefined") {
            calling_source_arr.push(result[s]);
          }
        });
        if (calling_source_arr.length > 0) {
          let source_text = `${result.calling_source}-`;
          calling_source_arr.forEach((c, i) => {
            source_text += i === calling_source_arr.length - 1 ? c : `${c}-`;
          });
          result.source = source_text;
        } else {
          result.source = result.calling_source;
        }
      } else {
        result.source = result.calling_source
      }

      //計價專案
      let priceObj = {
        receive_money: "",
        self_pay: "",
        service_cost: ""
      }, payout = ""
      // if (response.Result.case_status > 6) {
      let filter_obj = {
        customer_row_id: result.customer_row_id,
        case_type_id: result.case_type_id,
        origin_county: result.origin_county,
        origin_town: result.origin_town,
        destination_county: result.destination_county,
        destination_town: result.destination_town,
      }
      let param = ''
      Object.keys(filter_obj).forEach((key, i) => {
        param += i === 0 ? `${key}=${filter_obj[key]}` : `&${key}=${filter_obj[key]}`
      })
      let priceModule = await CasePriceId(result.customer_row_id, result.case_type_id, param)

      if (priceModule && !priceModule.Error) {
        let price_module = priceModule.Result
        result.project_type = price_module ? price_module.name : ""
        result.receipt_no = price_module?price_module.receipt_no:''
        // result.receipt_title = price_module?.receipt_title
        // payout = price_module && price_module.target ? price_module.target : ''
        // if (price_module) {
          let param = ''
          if(price_module) param =`price_row_id=${price_module.row_id}`

          let price_items_old = await CloseCasePriceList(caseId,result.vehicle_sn,param)
          let price_items = await CloseCasePriceLists(result.case_info_row_id)
         
          // let concate = price_items_old.Result.concat(price_items.Result)
          let items = []
          if(price_items_old.Result && result.case_status===10) {
            items = items.concat(price_items_old.Result)
          }
          //result.case_status===10 待結案
          if(price_items.Result ){
            items = items.concat(price_items.Result)
          }

          if (items.length >0) {
            items.forEach(i => {
              Object.keys(priceObj).forEach(price => {
                if (priceObj[price] === "") {
                  let obj = {
                    type: "accordian",
                    total:  i[price]!==null?i[price]:0,
                    text: i[price]!==null?i[price]:0,
                    subdata: [{ type: i.item, amount: i[price]?i[price]:0 }]
                  }
                  priceObj[price] = obj
                }else{
                  priceObj[price]['subdata'].push({ type: i.item, amount: i[price]? i[price]:0})
                }

              })
            })
            result.pricing_items = items
          }
      }
      // }

      Object.keys(priceObj).forEach(price => {
        if(priceObj[price]['subdata']){
          let total =0
          priceObj[price]['subdata'].forEach(a=>total+=a.amount)
          priceObj[price]["text"] = total
          priceObj[price]["total"] = total
        }
        result[price] = priceObj[price]
      })
      //請款對象
      // result.payout = payout
      //服務費用
      result.serviceFee = {
        type: "accordian",
        text: result.service_Fee ? result.service_Fee : "",
        subdata: result.fee_subdata ? result.fee_subdata : [],
      }
      //自費金額
      result.selfPaying = result.self_paying ? result.self_paying : "";
      //請款金額
      result.payoutAmount = {
        type: "accordian",
        text: result.payout_amount ? result.payout_amount : "",
        subdata: result.fee_subdata ? result.fee_subdata : [],
      };
      //車輛顏色
      result.color = result.vehicle_color
      result.coupon = !result.coupon ? "" : result.coupon;
      //費用歸屬單位
      result.expense_attribution_unit = !result.expense_attribution_unit ? "" : result.expense_attribution_unit;
      let filter_complain = complain.filter(c => c.value === result.complain_reason)
      let complain_text = ''
      if (filter_complain.length > 0) {
        complain_text = filter_complain[0]["text"]
      }
      if (result.complain_reason === 0) complain_text += ` (${result.complain})`
      result.complain_text = complain_text

      //執行狀態
      if(result.execution_info_row_id){
        let execute = await GetExecutionInfo(result.execution_info_row_id)
        if (execute && !execute.Error) {
          result.execute_id = execute.Result.id
        }
        result.ExecutionStatus = {
          type: "icons", color: StatusObj(result.case_status).color, title: result.execution_status,
          navigate: `/execution/${result.execution_info_row_id}`, icon: <RightOutlined />
        }
      }else{
        result.ExecutionStatus =''
        result.execute_id=''
      }
      result.destination_photo = result.destination_photos.length>0?{type:"photo", data:result.destination_photos, icon:<FileImageOutlined />, prefixText:"下架"} :"-"
      result.origin_photo = result.origin_photos.length>0?{type:"photo", data:result.origin_photos, icon:<FileImageOutlined />, prefixText:"上架"} :"-"
      result.vehicle_around_video = result.vehicle_around.length>0?{type:"photo", data:result.vehicle_around, icon:<VideoCameraOutlined />, video:true} :"-"
      setTransportType(result.transport_type)
      let car_info = {
        case_row_id: result.case_info_row_id,
        case_no: caseId,
        case_status: result.case_status,
        power_status: result.power_status,
        vehicle_sn: result.vehicle_sn,
        vehicle_model_name: result.car_model_text,
        origin: result.Origin,
        origin_lat:result.origin_lat,
        origin_lng:result.origin_lng,
        origin_floor:result.origin_floor,
        destination: result.Destination,
        destination_lat:result.destination_lat,
        destination_lng:result.destination_lng,
        destination_floor:result.destination_floor,
      }
      setSelectedCarList([car_info])
      if (result.case_type_id === '013') {
        result.car_hood_text = `${result.car_hood_type}${result.car_hood_note ? `（${result.car_hood_note}）` : ""}`
      }
      result.remark = { type: 'remark', content: result.c_service_self_note }
      result.execute_time = (response.Result.case_status===5 || response.Result.case_status===4 ) && history.Result.length>0?history.Result[history.Result.length-1].create_time:""
      result.car_info = car_info
      setData(result);
    }
  }

  const ButtonHeader = (status) => {
    let arr = []
    if (UserPermission && UserPermission[routeId] === 2) {
      if (status === 0) {
        arr = [
          <Button key="續單" onClick={repeatedCase}>續單</Button>,
          <Button key="客訴註記" onClick={() => setModalVal("3")}>再次派遣</Button>
        ]
      } else if (status > 6 && status !== 11) {
        arr = arr.concat([<Button key="客訴註記" onClick={() => setModalVal("2")}>客訴註記</Button>, <Button key="續單" onClick={repeatedCase}>
          續單
        </Button>])
        if (status === 7 || status === 10) {
          arr.push(<Button
            type='primary'
            key="結案"
            // icon={<PayCircleOutlined />}
            onClick={() => setVisible(2)}
          > 結案 </Button>)
        } else {
          arr.push(<Button
            key="結後修改"
          // icon={<PayCircleOutlined />}
          onClick={() => setVisible(8)}
          >結後修改</Button>)
        }
      } else {
        arr = arr.concat([<Button key="續單" onClick={repeatedCase}>
          續單
        </Button>])
        if (status > 1) {
          arr = arr.concat([<Button key="紙本結案" onClick={() => setModalVal("1")}
          > 紙本結案 </Button>,
          <Button
            key="案件取消"
            onClick={cancel}
          >
            案件取消
          </Button>
          ])
          if(data.case_type_id !=="014" && data.case_type_id !=="015" ){
            if (status === 2) {
              arr.push(<Button
                key="手動派遣"
                onClick={() => setVisible(1)}
              >
                手動派遣
              </Button>)
            } else if (status < 6 || (status === 6 && (!data.execution_status || data.execution_status === "前往起運點" || data.execution_status === "抵達起運點")) || status === 11) {
              arr.push(<Button
                onClick={() => setVisible(7)}
              >
                改派他人
              </Button>)
            }
          }
          
          if(status === 11){
            arr.push(<Button type='primary'
              onClick={() => continueExecuteCase()}
            > 繼續執行 </Button>)
          }
        } else {
          arr = [
            <Button key="備註編輯"
              onClick={() => setModalVal("4")}
            >備註編輯</Button>,
            <Button key="案件審核" onClick={() => {
              navigate("/cases/verify", { state: { ...state } })
            }} type='primary'>
              案件審核
            </Button>
          ]
        }
      }
    }
    return arr
  }
  useEffect(() => {
    if (DispatchCarBrand !== null) setAllCarBrand(DispatchCarBrand)
    if (Town !== null && AllCarBrand !== null) {
      fetchAPI()
    }
  }, [DispatchCarBrand, AllCarBrand])
  useEffect(()=>{
    if(data){
      if((data.case_type_id ==="014" ||  data.case_type_id ==="015")&&data.case_status ===2){
        confirm({
          icon: false,
          content: <p>因此為特殊案型，系統無法進行技師媒合，請直接通知技師執行，並進行紙本結案，選擇執行技師即可。</p>,
          okText:'紙本結案',
          onOk() {
            setModalVal("1")
          },
          onCancel() {},
        });
      }
    }
  },[data])
  useEffect(() => {
    GetServicePointList('page_index=0&page_size=-1').then(res => {
      if (res && !res.Error) {
        setPointList(res.Result.Data)
      }
    })
  }, [])
  return (
    <div className="container" style={ContainerWidth}>
      <div>
        {data ? (
          <>
            <PageHeader
              style={{ background: "white", width: "100%" }}
              title={`${caseId} - ${vehicle_sn}`}
              onBack={() =>
                navigate(`/cases`, {
                  state: {
                    dataDate,
                    pagination: state.pagination,
                    currentTab: state.currentTab,
                    ...state
                  },
                })
              }
              className="site-page-header"
              tags={
                <Tag color={StatusObj(status).tagColor}>
                  {data.execution_status && status === "6"
                    ? data.execution_status
                    : StatusObj(status).text}
                </Tag>
              }
              extra={ButtonHeader(status)}
              breadcrumb={<BreadCrumb />}
            ></PageHeader>
            <div
              className="split_block"
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              <div
                className="content_block"
                style={{ padding: "0 24px 24px 24px" }}
              >
                {UserPermission && UserPermission[routeId] === 2 && (data && data.case_type_id!=="014" && data.case_type_id!=="015") && (status < 6 || status === 11) && (
                    <ModalCard
                      data={data}
                      status={status}
                      setVisible={(visible) => {
                        if (visible === "navigate") {
                          navigate("/cases/verify", { state: { ...state } });
                        } else {
                          if(visible==='3'){
                            setModalVal(visible)
                          }else{
                            setVisible(visible)
                          }
                        }
                      }}
                      Success={(val) => {
                        if (val) fetchAPI();
                      }}
                      continueExecute={(val)=>{
                        if(val) continueExecuteCase()
                      }}
                    />
                  )}
                <Card
                  style={{ width: "100%", margin: "24px 0" }}
                  title="案件資訊"
                extra={
                  <Button
                      type="text"
                      style={{ paddingRight: 0 }}
                      onClick={() => setVisible(3)}
                    >
                      修改
                      <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                    </Button>
                }
                >
                  <CustomCard
                    remarkStatus={(val) => setModalVal("4")}
                    header={[
                      { key: "customer", name: "客戶別" },
                      { key: "case_type", name: "案件類型" },
                      { key: "deliveryTime", name: "運送時間" },
                      { key: "origin_add", name: "起運點" },
                      { key: "destination_add", name: "迄運點" },
                      { key: "car_model_text", name: "廠牌車型" },
                      { key: "VIN", name: "車牌 / 車身 / 引擎號碼" },
                      { key: "carStatus", name: "車輛狀態" },
                      { key: "sender", name: "交車人" },
                      { key: "receiver", name: "接車人" },
                      { key: "source", name: "案件來源" },
                      {
                        key: extraService[data.case_type_id]
                          ? extraService[data.case_type_id].key
                          : "",
                        name: extraService[data.case_type_id]
                          ? extraService[data.case_type_id].name
                          : "",
                      },
                      { key: "project_type", name: "計價專案" },
                      { key: "request_for_payment", name: "請款對象" },
                      { key: "self_pay", name: "自費金額" },
                      { key: "service_cost", name: "服務費用" },
                      { key: "receive_money", name: "請款金額" },
                      { key: "expense_attribution_unit", name: "費用歸屬單位" },
                      { key: "to_customer_service_note", name: "客戶上單備註" },
                      { key: "note", name: "客服給技師的備註" },
                      { key: "remark", name: "客服備註" },
                      // { key: "to_customer_service_note", name: "狀態變更備註" },
                      { key: "complain_text", name: "客訴註記" },
                    ]}
                    data={data}
                  />
                </Card>
                {status > 2 && (
                  <Card
                    style={{ width: "100%", margin: "24px 0" }}
                    title="執行資訊"
                  >
                    <CustomCard
                      header={[
                        { key: "service_point", name: "服務據點" },
                        { key: "driver", name: "服務技師" },
                        { key: "trailer", name: "服務車輛" },
                        { key: "ExecutionStatus", name: "執行狀態" },
                        { key: "origin_photo", name: "起運點照片" },
                        { key: "destination_photo", name: "迄運點照片" },
                        { key: "vehicle_around_video", name: "環車照片" },
                        { key: "miles", name: "載運里程" },
                        { key: data.receipt? "receipt":"", name: "電子四聯單" },
                      ]}
                      data={data}
                    />
                  </Card>
                )}
              </div>
              {history && <History data={history} />}
            </div>
          </>
        ) : (
          <div style={{ margin: 24, background: "white" }}>
            <LoadingBlock />
          </div>
        )}
      </div>
      {/* 手動派遣 */}
      {visible === null ? (
        <></>
      ) : visible === 1 || visible === 7 ? (
        <ManualDispatch
          reasonOption={reasonOption}
          carData={SelectedCarList}
          visible={visible}
          SetVisible={(status) => {
            if (status === "success") {
              //手動派遣成功
              fetchAPI();
              setVisible(null);
            } else {
              if (status === null) {
                let car = SelectedCarList.filter(
                  (car) => car.case_row_id === data.case_info_row_id
                );
                setSelectedCarList(car);
              }
              setVisible(status);
            }
          }}
          CaseStatus={(status) => setStatus(status)}
          caseId={{ caseId, id: initData.vehicle_sn }}
          setBtnStatus={(status) => setBtnDisabled(status)}
        />
      ) : visible === 2 || visible === 8 ? (
        <CaseClosure
          visible={visible}
          SetVisible={(status) => {
            setVisible(status);
          }}
          onConfirm={(status) => {
            setVisible(null);
            fetchAPI();
          }}
          caseData={{ caseId, ...data }}
          CertainCase={certain_case}
          SetStatus={(status) => setStatus(status)}
          CasesList={CasesList}
          SetCaseClosureHistory={(res) => setCaseClosureHistory(res)}
        />
      ) : visible === 3 ? (
        <CaseEdit
          visible={true}
          caseData={data}
          reportedMethod={reportedMethod}
          setEditData={(value) => setEditData(value)}
          SetVisible={(status) => setVisible(status)}
          caseId={caseId}
          TownOption={Town}
          setSubmitForm={(success) => {
            if (success) {
              fetchAPI();
              setVisible(null);
            }
          }}
        />
      ) : visible === "4a" || visible === "4b" ? (
        <TechnicianList
          reasonOption={reasonOption}
          carData={SelectedCarList}
          caseData={data}
          visible={visible}
          onSuccess={(val) => {
            if (val) {
              setVisible(null);
              fetchAPI();
            }
          }}
          SetVisible={(status) => setVisible(status)}
        />
      ) : visible === "5a" || visible === "5b" ? (
        <NonOfficalDriver
          visible={visible}
          reasonOption={reasonOption}
          carData={SelectedCarList}
          caseData={data}
          SetVisible={(status) => {
            if (status === null) {
              setVisible(status);
              fetchAPI();
            } else {
              setVisible(status);
            }
          }}
        />
      ) : visible === 6 ? (
        <CarList
          carList={SelectedCarList}
          SetVisible={(status) => setVisible(status)}
          onConfirm={(val) => {
            let carlist = [{...data.car_info}];
            carlist = carlist.concat(val)
            const unique = carlist.filter(
              (obj, index) =>
              carlist.findIndex((item) => item.case_row_id === obj.case_row_id) === index
            );

              // if(val.length === 0){
              //   carlist = carlist.filter(c=>c.case_row_id === data.case_info_row_id)
              // }else{
              //   val.forEach((v) => {
              //   let filter = carlist.filter(
              //     (car) => car.case_row_id === v.case_row_id
              //   );
              //   if (filter.length === 0) {
              //     carlist.push(v);
              //   }
              // });
              // }
            let Visbile = status > 2 ? 7 : 1;
            setVisible(Visbile);
            setSelectedCarList(unique);
          }}
        />
      ) : (
        <></>
      )}
      { data && (
        <Modal
          title={modalVal === "1" ? "紙本結案" : modalVal === "2" ? "客訴註記" : modalVal === "4" ? "備註" : (<div style={{ display: "flex" }}>
            <h3>再次派遣</h3>
            <p
              style={{
                color: "rgba(0,0,0,0.45)",
                fontSize: 14,
                paddingLeft: 8,
              }}
            > 請替案件設定新的運送時間 </p>
          </div>)
          }
          visible={modalVal}
          onOk={() => editForm.current.submit()}
          cancelText="離開"
          onCancel={() => {
            editForm.current.resetFields()
            setModalVal(null)
          }}
          width={416}
        >
          <Form
            name="basic"
            layout="vertical"
            initialValues={modalVal !== "3" ? data : {transport_type:"0"}}
            ref={editForm}
            onFinish={(val) => modalPost(val)}
          >
            {
              modalVal === "1" && (
                <>
                  <Form.Item
                    label="執行日期"
                    name="executeDate"
                    style={{ display: "block" }}
                    rules={[{ required: true, message: "請選擇執行日期!" }]}
                  >
                    <DatePicker style={{ width: 368 }} />
                  </Form.Item>
                  <Form.Item
                    label="執行據點"
                    name="service_point"
                    style={{ display: "block" }}
                    rules={[{ required: true, message: "請選擇執行據點!" }]}
                  >
                    <Select
                      placeholder="點擊選擇"
                      showSearch
                      onChange={(val) => {
                        GetServicePointDriverList(-1, 0, val).then((res) => {
                          if (res && !res.Error) {
                            let Data = res.Result.Data.map((d) => {
                              d.value = d.name;
                              d.label = d.label;
                              return d;
                            });
                            setAllPointDriver(Data)
                            setPointDriver(Data);
                          }
                        });
                      }}
                      style={{ width: 368 }}
                    >
                      {pointList.map((point) => (
                        <Option
                          key={point.row_id}
                          value={`${point.row_id},${point.name}`}
                        >
                          {point.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="執行技師"
                    name="driver"
                    style={{ display: "block" }}
                    rules={[{ required: true, message: "請選執行技師!" }]}
                  >
                    <AutoComplete
                      style={{ width: 368 }}
                      placeholder={"執行技師"}
                      options={pointDriver}
                      onSearch={(value)=>{
                        let filter = AllPointDriver.filter(driver=> driver.name.toUpperCase().indexOf(value.toUpperCase())>-1)
                        let final = filter.length>0?filter:[{value:`${value} / 臨時技師`,label:`新增 “${value}” 作為臨時技師`}]
                        setPointDriver(final)
                      }}
                    />
                  </Form.Item>
                </>
              )
            }
            {
              modalVal === "2" && (
                <>
                  <Form.Item
                    label="客訴原因"
                    name="complain_reason"
                    rules={[{ required: true, message: "請選擇客訴原因!" }]}
                  >
                    <Select
                      placeholder="點擊選擇"
                      style={{ width: 368 }}
                      onChange={(value) => {
                        if (value === 0) {
                          setComplainOther(true);
                        } else {
                          setComplainOther(false);
                        }
                      }}
                    >
                      {complain.map((c) => (
                        <Option key={c.text} value={c.value}>
                          {c.text}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {complainOther && (
                    <Form.Item
                      label="備註"
                      name="complain"
                      rules={[{ required: true, message: "請輸入備註!" }]}
                    >
                      <Input placeholder="example" />
                    </Form.Item>
                  )}
                </>
              )
            }
            {
              modalVal === "3" && (
                <>
                  <Form.Item
                    label="運送時間"
                    name="transport_type"
                    rules={[{ required: true, message: "請選擇運送時間!" }]}
                  >
                    <Radio.Group
                      onChange={(e) => setTransportType(e.target.value)}
                    >
                      <Radio.Button value="0">儘快</Radio.Button>
                      <Radio.Button value="1">指定時間</Radio.Button>
                      {/* {!data.is_VIP && (
                        <Radio.Button value="2">加價特急件</Radio.Button>
                      )} */}
                    </Radio.Group>
                  </Form.Item>
                  {transportType=== "1" && (
                    <>
                      <Form.Item
                        label="日期"
                        name="date"
                        rules={[{ required: true, message: "請選擇運送時間!" }]}
                      >
                        <DatePicker 
                          style={{width:"100%"}}
                          disabledDate={(current) => {
                            let now = moment().format("YYYY-MM-DD");
                            return current < moment(`${now} 00:00:00`);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="時間"
                        name="time"
                        rules={[{ required: true, message: "請選擇運送時間!" }]}
                      >
                        <TimePicker.RangePicker
                          style={{ width: "100%" }}
                          format={"HH:mm"}
                          minuteStep={60}
                        />
                      </Form.Item>
                    </>
                  )}
                </>
              )
            }
            {
              modalVal === "4" && (
                <Form.Item
                  label=""
                  name="c_service_self_note"
                  rules={[{ required: true, message: "請輸入備註!" }]}
                >
                  <TextArea
                    defaultValue={data.c_service_self_note}
                    placeholder="額外的備註請寫在此......"
                    style={{ width: 368 }}
                  // onKeyUp={(e) => setRemarkContent(e.target.value)}
                  />
                </Form.Item>
              )
            }
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default Case;
