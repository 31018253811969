import React, { useState, useEffect, useRef } from 'react';
import BreadCrumb from '../../components/shared/BreadCrumb'
import { DownloadOutlined, EnterOutlined } from '@ant-design/icons'
import { PageHeader, Button, DatePicker, Table, Form, Select } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import moment from 'moment'
import { GetReport } from '../../api/Statement';
import { DownloadFile } from '../../util/download';
import { numberWithCommas } from '../../util/Number';
import { GetCustomerList } from '../../api/Customer';
const { RangePicker } = DatePicker;

const Index = ({ pageTitle, ContainerWidth }) => {
    let navigate = useNavigate();
    const formRef = useRef()
    const location = useLocation()
    const [data, setData] = useState(null)
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
    const [customerOption, setCustomerOption] = useState([])
    const [caseTypeList, setCaseTypeList] = useState([])
    const [region, setRegion] = useState(null)
    const [loading, setLoading] = useState(false)
    let CustomerList = useSelector(state => state.CustomerList)
    let case_type = useSelector(state => state.caseType)
    useEffect(() => {
        document.title = pageTitle
    }, [])
    useEffect(() => {
        let container = document.querySelector(".container")
        container.scrollTo(0, 0)
    }, [pagination])
    useEffect(() => {
        let param = 'page_index=0&page_size=-1'
        if (region) {
            param += `1&execution_region_type=${region}`;
        }
        GetCustomerList(param).then(res => {
            if (res && !res.Error) {
                const { Data } = res.Result
                // console.log(Data)
                setCustomerOption(Data)
            }
        })
        formRef.current?.resetFields()
        formRef.current?.setFieldsValue({ execution_region_type: region })
    }, [region])
    useEffect(() => {
        // console.log(CustomerList)
        // if (CustomerList) {
        //     setCustomerOption(CustomerList)
        // }
    }, [CustomerList])
    useEffect(() => {
        if (case_type) setCaseTypeList(case_type)
    }, [case_type])
    const onFinish = async (type, val) => {
        setLoading(true)
        let value = type === 'read' ? val : formRef.current.getFieldValue()
        // let customer = value.customer.split(",")
        // let customer = customerOption.filter(d=>d.id === value.customer.split(",")[0])
        // let param = `custom_row_id=${customer[0]['row_id']}&case_type=${value.case_type}&year=${value.date.format("YYYY")}&month=${value.date.format("MM")}`

        value.startdate = value.date[0].format("YYYY/MM")
        value.enddate = value.date[1].format("YYYY/MM")

        let res = await DownloadFile(`ReceiveReport/DownloadMatrixReport`, value)
        if (res) setLoading(false)
        // return false
        // if(type ==='read'){
        //     let res = await GetReport(value)

        //     if(res && !res.Error) {
        //         let CaseList = res.Result.CaseList.map(r=>{
        //             r.caseNo = `${r.case_no}-${r.vehicle_sn}`
        //             return r
        //         })
        //         setData({...res.Result,CaseList})
        //     }
        //     setPagination({ current: 1, pageSize: 10 })
        // }else{
        //     DownloadFile(`ReceiveReport/DownloadReport?${param}`)
        // }
    }
    const onCancel = () => {
        setData(null)
        formRef.current.resetFields()
    }
    const columns = [
        { title: '案件編號', dataIndex: 'caseNo' },
        { title: '拍賣單號', dataIndex: 'auction_no', render: val => val ? val : "-" },
        { title: '運送日期', dataIndex: 'transport_date', render: val => val ? moment(val).format("YYYY/MM/DD") : "-" },
        { title: '完成日期', dataIndex: 'execution_time', render: val => val ? moment(val).format("YYYY/MM/DD") : "-" },
        { title: '車牌 / 引擎 / 車身碼', dataIndex: 'VIN', render: val => val ? val : "-" },
        { title: '廠牌', dataIndex: 'vehicle_brand_name' },
        { title: '車型', dataIndex: 'vehicle_model_name' },
        { title: '車色', dataIndex: 'vehicle_color', render: val => val ? val : "-" },
        { title: '起運點', dataIndex: 'origin_place_name', render: val => val ? val : "-" },
        { title: '訖運點', dataIndex: 'destination_place_name', render: val => val ? val : "-" },
        { title: '公里數(KM)', dataIndex: 'KM', render: val => val ? val : "-" },
        { title: '原價', dataIndex: 'origin_price', render: val => val ? val : 0 },
        { title: '折扣金額', dataIndex: 'discount', render: val => val ? val : 0 },
        { title: '折價方案', dataIndex: 'marketing_plan', render: val => val ? val : "-" },
        { title: '請款金額', dataIndex: 'receive_money', render: val => val ? val : 0 },
        { title: '費用歸屬單位', dataIndex: 'expense_attribution_unit', render: val => val ? val : "-" },
        { title: '請款對象', dataIndex: 'request_for_payment', render: val => val ? val : "-" },
        { title: '發票抬頭', dataIndex: 'receipt_title', render: val => val ? val : "-" },
        { title: '發票統編', dataIndex: 'receipt_no', render: val => val ? val : "-" },
        {
            title: '',
            dataIndex: 'destination',
            render: (status, record) => (<Button
                type='link'
                onClick={() => {
                    let origin = window.location.origin
                    window.open(`${origin}${process.env.REACT_APP_BASE_URL}/cases/${record.case_no}?vehicle_sn=${record.vehicle_sn}`)
                    // navigate(`/cases/${record.case_no}`, {
                    //   state: {vehicle_sn: record.vehicle_sn}})
                }}
            >查看</Button>)
        }
    ]
    return (
        <div className="container" style={{ ...ContainerWidth, position: "relative" }}>
            <PageHeader
                style={{ background: "white", width: "100%" }}
                title="請款報表"
                className="site-page-header"
                breadcrumb={<BreadCrumb />}
            >
            </PageHeader>
            <div style={{ margin: 24, marginBottom: 102, background: 'white', minheight: data ? "calc(100% - 236px)" : "auto", }}>
                <div style={{ borderBottom: '1px solid #F0F0F0' }}>
                    <Form
                        name="basic"
                        layout="vertical"
                        ref={formRef}
                        style={{ padding: 16 }}
                        onFinish={(val) => onFinish('read', val)}
                    >
                        <div style={{ display: "flex" }}>
                            <Form.Item
                                label="執行分區"
                                name="execution_region_type"
                                style={{ marginRight: 16, width: 200 }}
                            // rules={[{ required: true, message: '請輸入執行分區！' }]}
                            >

                                <Select
                                    allowClear
                                    placeholder="請選
                                    擇"
                                    onChange={(e) => setRegion(e)}
                                    options={[
                                        { label: '北區', value: 1 },
                                        { label: '中區', value: 2 },
                                        { label: '全業', value: 3 },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                label="客戶名稱"
                                name="custom_row_ids"
                                rules={[{ required: true, message: '請輸入客戶名稱！' }]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: 255 }}
                                    placeholder="請選擇客戶別"
                                    maxTagCount='responsive'
                                // onChange={(e)=>{
                                //     console.log(e)
                                // }}
                                // options={customerOption}
                                >

                                    {
                                        customerOption && customerOption.map(o => (<option
                                            key={`${o.id}${o.name}`}
                                            value={o.row_id}
                                        >{`${o.id}-${o.name}`}</option>))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="案件類型"
                                name="case_types"
                                rules={[{ required: true, message: '請選擇案件類型！' }]}
                            >

                                <Select
                                    allowClear
                                    style={{ width: "304px", margin: "0 16px" }}
                                    placeholder="點擊選擇"
                                    mode="multiple"
                                    maxTagCount='responsive'
                                >
                                    {
                                        caseTypeList.map(c => (<option key={`${c.Code} - ${c.Name}運送`} value={c.Code}>{c.Name}運送</option>))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="報表月份"
                                name="date"
                                rules={[{ required: true, message: '請選擇報表月份！' }]}
                            >
                                <RangePicker
                                    picker="month"
                                    disabledDate={(current) => {
                                        let now = moment().format("YYYY-MM-DD");
                                        return current > moment(`${now} 00:00:00`);
                                    }}
                                    style={{ width: 304 }} />
                            </Form.Item>
                            {/* <Form.Item
                                label="請款窗口"
                                name="inCharge"
                            >
                                <Input style={{ width: 272 }} placeholder='example' />

                            </Form.Item> */}
                        </div>
                        <div style={{ display: "flex" }}>
                            <Button type='primary'
                                disabled={loading}
                                htmlType="submit" icon={<EnterOutlined />}>製作{loading && '中'} </Button>
                            <Button type='text' disabled={loading} onClick={onCancel}>清除</Button>
                        </div>
                    </Form>
                </div>
                {
                    data && (<div style={{ padding: 24, minheight: "calc(100% - 168px)" }}>
                        <div style={{ display: 'grid', gridTemplateColumns: '30% 30% 20% 20%', padding: "16px 0", borderBottom: '1px solid #F0F0F0' }}>
                            <div>
                                <label style={{ fontSize: 14, color: "rgba(0,0,0,0.45)" }}>客戶別</label>
                                <div style={{ fontSize: 24, marginTop: 6 }}>{data.CustomId} / {data.CustomName}</div>
                            </div>
                            <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.03)', paddingLeft: 24 }}>
                                <label style={{ fontSize: 14, color: "rgba(0,0,0,0.45)" }}>計價模組&合約</label>
                                <div style={{ fontSize: 24, marginTop: 6, color: '#1890FF' }}>{data.PriceId} / {data.PriceName}</div>
                            </div>
                            <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.03)', paddingLeft: 24 }}>
                                <label style={{ fontSize: 14, color: "rgba(0,0,0,0.45)" }}>總案件數</label>
                                <div style={{ fontSize: 24, marginTop: 6 }}>{data.CaseCount}</div>
                            </div>
                            <div style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.03)', paddingLeft: 24, paddingRight: 24 }}>
                                <label style={{ fontSize: 14, color: "rgba(0,0,0,0.45)" }}>總請款金額</label>
                                <div style={{ fontSize: 24, marginTop: 6 }}>$ {numberWithCommas(data.ReceiveSum)}</div>
                            </div>
                        </div>
                        <Table
                            scroll={{ x: 3000 }}
                            className='table_block'
                            columns={columns}
                            rowKey={record => record.id}
                            dataSource={data.CaseList}
                            pagination={data.CaseList.length > 10 ? {
                                current: pagination.current,
                                total: data.length,
                                showTotal: (total, range) =>
                                    `${range[0]}-${range[1]} 列（共${total}  列）`,
                            } : false}
                            onChange={(pagination) => setPagination(pagination)}
                            style={{ padding: "24px 0", minheight: "calc(100% - 72px)" }}
                        />
                    </div>)
                }

            </div>
            {
                data && (
                    <div style={{ background: 'white', position: "fixed", width: `calc(100% - ${ContainerWidth.marginLeft})`, bottom: 0, display: 'flex', justifyContent: 'flex-end', padding: 20, zIndex: 10 }}>
                        <Button type='text' onClick={onCancel}>取消</Button>
                        <Button type='primary' icon={<DownloadOutlined />} onClick={() => onFinish("download")}>表單下載</Button>
                    </div>
                )
            }
        </div>
    );
}

export default Index;
