import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import BreadCrumb from '../../components/shared/BreadCrumb'
import { PageHeader, Tag, Button, Card, Select, Drawer, Form, Input, Radio, Modal, DatePicker, Upload, Row, Statistic } from 'antd'
import { PauseCircleOutlined, CalendarOutlined, EditOutlined, FileTextOutlined, PhoneOutlined, CloseOutlined, UploadOutlined, RightOutlined, UserOutlined,EnterOutlined} from '@ant-design/icons'
import { GetServicePointDriver, GetServicePoint, UpdateServicePointDriver, GetDriverHistory, SetDriverLeaveOfficeDate, SetDriverTakeOfficeDate, UpdateDriverLicense ,CheckMobileNumber} from '../../api/ServicePoint'
import CustomCard from '../../components/shared/component/Card'
import moment from 'moment'
import { GetCarTypeList, GetUnoccupiedCarList } from '../../api/Code'
import LoadingBlock from '../../components/shared/LoadingBlock'
import { FilterConty } from '../../components/shared/util/FilterFunc'
import CustomCascader from '../../components/shared/component/CustomCascader'
import HistoryRecord from '../../components/shared/component/HistoryRecord'
import { RouteName } from '../../util/route'
import UnionIcon from '../../components/shared/UnionIcon'
import { useSelector } from 'react-redux'

const listHeader = [
    { key: "point_name", name: "據點名稱" },
    { key: "id", name: "技師編號" },
    { key: "driver_id", name: "身分證號" },
    { key: "birthday", name: "生日" },
    { key: "workStatus", name: "任職狀態 / 到職日" },
    { key: "licenseValidation", name: "駕照效期" },
    { key: "mobile_phone_number_text", name: "手機號碼" },
    { key: "address_text", name: "聯絡地址" },
    { key: "emergency_contact1_text", name: "緊急聯絡人 - 1" },
    { key: "emergency_contact2_text", name: "緊急聯絡人 - 2" },
    { key: "skill_text", name: "服務技能" },
    { key: "default_car_text", name: "常用車" },
    { key: "task_type_text", name: "優先接案" },
    { key: "workPeriod", name: "上班日" },
    { key: "workType", name: "出勤型態" },
    { key: "note", name: "備註" }
]

const Techniciandetail = ({ ContainerWidth }) => {
    let { technicianId, pointId } = useParams()
    const { state, pathname } = useLocation()
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo)
    let UserPermission = UserInfo ? UserInfo.permission.route : null
    let County = useSelector(state => state.county)
    const navigate = useNavigate()
    const editForm = useRef(), settingForm = useRef()
    const [status, setStatus] = useState(1)
    const [modalStatus, setModalStatus] = useState(null)
    const [carTypeList, setCarTypeList] = useState(null)
    const [workSelected, setWorkSelected] = useState({})
    const [data, setData] = useState(null)
    const [WorkingType, setWorkingType] = useState("")
    const [IdPic, setIdPic] = useState([])
    const [licensePic, setLicensePic] = useState([])
    const [history, setHistory] = useState(null)
    const [UnoccupiedCarList, setUnoccupiedCarList] = useState([])
    const [isValid, setIsValid] = useState(null)
    const work = [
        { title: '「星期一」出勤時段', key: 'mon', day: "一" },
        { title: '「星期二」出勤時段', key: 'tue', day: "二" },
        { title: '「星期三」出勤時段', key: 'wed', day: "三" },
        { title: '「星期四」出勤時段', key: 'thu', day: "四" },
        { title: '「星期五」出勤時段', key: 'fri', day: "五" },
        { title: '「星期六」出勤時段', key: 'sat', day: "六" },
        { title: '「星期日」出勤時段', key: 'sun', day: "日" },
    ]

    let dateKey = { "2": "", "3a": "leave_office_date", "3b": "take_office_date" }
    const settingOnSubmit = async (val) => {
        let param = ''
        Object.keys(val).forEach((key, i) => {
            let Key = key
            let value = val[key]
            if (key === "date") {
                Key = dateKey[modalStatus]
                value = val[key].format("YYYY-MM-DD")
            }
            param += i === 0 ? `${Key}=${value}` : `&${Key}=${value}`
        })
        param += `&driver_row_id=${technicianId}`
        let res
        if (modalStatus === "2") {
            let obj = {
                row_id: technicianId,
                driving_license_expiration_date: val.date.format(),
                driving_license_front: val.driving_license.fileList[0],
                driving_license_back: val.driving_license.fileList[0],
            }
            res = await UpdateDriverLicense(obj)

        } else if (modalStatus === "3a") {
            res = await SetDriverLeaveOfficeDate(param)
        } else if (modalStatus === "3b") {
            res = await SetDriverTakeOfficeDate(param)
        }
        if (res && !res.Error) {
            settingForm.current.resetFields()
            setModalStatus(null)
            fetchApi()
        }
    }
    const onClose = () => {
        if (modalStatus === "1") {
            setWorkSelected(data.work)
        } else {
            settingForm.current.resetFields()
        }
        setModalStatus(null)
    }
    const checkMobile = async () => {
        let val = editForm.current.getFieldValue().mobile_phone_number
        let valid= false
        if (val) {
            let res = await CheckMobileNumber(val)
            if(res && !res.Error){
                valid = res.Result.Success
            }else{
                valid = false
            }
        }
        if(val === data.mobile_phone_number){
            valid = true
        }
        setIsValid(valid)
        return valid
    }
    const fetchApi = async () => {
        GetUnoccupiedCarList(pointId).then(res => {
            if (res && !res.Error) {
                setUnoccupiedCarList(res.Result.Data)
            }
        })
        let carType = carTypeList ? [...carTypeList] : []
        if (!carTypeList) {
            let car_type = await GetCarTypeList()
            if (car_type && !car_type.Error) {
                carType = car_type.Result.map(r => {
                    r.value = r.Code
                    r.label = r.Name
                    return r
                })
                setCarTypeList(carType)
            }
        }
        //技師歷程
        GetDriverHistory(-1, 0, technicianId).then(res => {
            if (res && !res.Error) {
                setHistory(res.Result.Data.reverse())
            }
        })
        let res_point = await GetServicePoint(pointId)
        let point_name = ''
        if (res_point && !res_point.Error) {
            point_name = res_point.Result.name
        }
        let current = moment().toDate().getTime()
        let res = await GetServicePointDriver(technicianId)
        let format = "YYYY/MM/DD"
        const format_time = "hh:mm"
        if (res && !res.Error) {
            let data = res.Result
            let phone = ["mobile_phone_number", "emergency_contact1", "emergency_contact2"]
            let id_photo = []
            if (data.id_picture_front) id_photo.push("01")
            if (data.id_picture_back) id_photo.push("02")
            data.driver_id = !data.id_picture_back && !data.id_picture_front ? data.id_no : { type: "icons", icon: <FileTextOutlined />, label: data.id_no, photoArr: id_photo, url: `api/v1/ServicePoint/Driver/DriveFile?row_id=${technicianId}` }
            data.birthday = moment(data.birthday).format(format)
            data.bod = moment(data.birthday)
            let driving_license = moment(data.driving_license_expiration_date).toDate().getTime()
            let driving_license_obj = {
                status: driving_license >= current ? "有效" : "無效",
                color: driving_license >= current ? "#52C41A" : "rgb(217, 217, 217)",
                date: moment(data.driving_license_expiration_date).format(format)
            }
            data.licenseValidation = { type: "status", content: [driving_license_obj.status, driving_license_obj.date], color: driving_license_obj.color }
            data.drivingLicenseDate = moment(data.driving_license_expiration_date)
            let status =  moment().isBetween(moment(data.take_office_date).format("YYYY/MM/DD"), moment(data.leave_office_date).format("YYYY/MM/DD"))
            let after_take_office = moment(moment().format("YYYY-MM-DD")).isAfter(moment(data.take_office_date).format("YYYY/MM/DD")) 
            // || moment().format("YYYY-MM-DD") === moment(data.take_office_date).format("YYYY-MM-DD"); 
            let work_content = !after_take_office ? ["尚未到職"]: status?["在職",moment(data.take_office_date).format(format)]:["離職"]
           

            data.workStatus = { type: "status", content: work_content, color: !after_take_office?'#FA8C16':status?'#52C41A':'rgba(217,217,217,1)' }
            setStatus(!after_take_office?-1:status?1:0)
            data.workDate = moment(data.take_office_date)
            data.workType = data.working_type === "1" ? "正式" : "支援"
            phone.forEach(p => {
                let phone_key = p === "mobile_phone_number" ? p : `${p}_tel`
                let key = `${p}_text`
                if (p === "mobile_phone_number") {
                    data[key] = { type: "icons", link: 'tel', icon: <PhoneOutlined />, label: data[phone_key] }
                } else {
                    if (!data[p] && !data[phone_key]) {
                        data[key] = ''
                    } else if(data[p] && !data[phone_key]){
                        data[key] = data[p]
                    }else if (data[p] && data[phone_key]) {
                        data[key] = { type: "icons", link: 'tel', icon: <PhoneOutlined />, title: data[p], label: data[phone_key] }
                    } else {
                        data[key] = { type: "icons", link: 'tel', icon: <PhoneOutlined />, label: data[phone_key] }
                    }
                }
            })
            data.default_car_text = data.working_type === "1"  && work_content[0] !=='離職'? { type: "tag", icon: <UnionIcon />, content: [`${data.plate_number} / ${data.car_kind_name}`] } : ""
            data.plate_number = data.working_type === "1"  && work_content[0] === '離職'?'-':data.plate_number
            data.Skill = data.skill.map(s => s.id)
            data.skill_text = data.skill.length > 0 ? { type: "tag", content: data.skill.map(s => s.name) } : ""
            data.task_type_text = data.task_type === "1" ? " 預約" : "即時"
            data.heart_rate = data.heart_rate ? data.heart_rate : "-"
            data.fatigue = data.fatigue ? data.fatigue : "-"
            data.blood_oxygen = data.blood_oxygen ? data.blood_oxygen : "-"
            let county = County[0]["children"]
            let full_add = ''
            let county_arr = county.filter(c=>c.value ===data.county)
            if(county_arr.length>0){
                full_add += county_arr[0]["label"]
                let town_arr = county_arr[0]["children"].filter(c=>c.value ===data.town)
                if(town_arr.length > 0) full_add += town_arr[0]["label"]
            }
            data.address_text = `${full_add}${data.address}`
            data.address_code = [data.county, data.town]
            data.point_name = point_name
            let operatingHour = []
            let work_selected = {}
            work.map(w => {
                let key = `${w.key}_work`
                if (data[key] === "1") {
                    operatingHour.push(w.day)
                    work_selected[w.key] = true
                }
            })
            data.work = work_selected
            setWorkSelected(work_selected)
            let workPeriod = ''
            operatingHour.forEach((h, i) => {
                workPeriod += i === 0 ? h : `、${h}`
            })
            data.workPeriod = workPeriod
            setWorkingType(data.working_type)
            setData(data)
        }
    }

    const editOnFinish = async (val) => {
        let valid = await checkMobile()
       if(!valid) return false
        const formdata = new FormData();
        let Data = { ...data }
        let obj_key = {drivingLicenseDate:"driving_license_expiration_date",bod:"birthday",workDate:"take_office_date"}
        for (let key in val) {
            if (val[key]) {
                if (key === 'Skill') {
                    let text = ''
                    val.Skill.map((d, i) => text += i === 0 ? d : `,${d}`)
                    Data.skill = text
                } else if (key.indexOf("picture") > -1 || key.indexOf("driving_license") > -1) {
                    let arr = ["front", "back"]
                    arr.map((a, i) => {
                        if (val[key][i]) {
                            Data[`${key}_${a}`] = val[key][i]["originFileObj"]
                        }
                    })
                }else if(obj_key[key]){
                    Data[obj_key[key]] = val[key].format("YYYY-MM-DD")
                }else {
                    Data[key] = val[key]
                }
            }
        }
        work.forEach(w => {
            // console.log(w)
            Data[`${w.key}_work`] = workSelected[w.key] ? 1 : 0
        })
        Object.keys(Data).map(key => {
            if (Data[key]) {
                formdata.append(key, Data[key])
            }
        })
        let res = await UpdateServicePointDriver(formdata)
        if (res && !res.Error) {
            fetchApi()
            setModalStatus(null)
        }
    }
    useEffect(() => {
        if(County)fetchApi()
    }, [County]);
    return (
        <div className='container' style={ContainerWidth}>
            {
                data ? (<>
                    <PageHeader
                        style={{ background: "white", width: "100%" }}
                        title={data.name}
                        onBack={() => navigate(`/points/${pointId}`, { state: state })}
                        className="site-page-header"
                        tags={<Tag color={status === -1 ? "orange" : status===0?"":"green"}>{status === -1 ? "尚未到職": status === 0? '離職': "在職"}</Tag>}
                        extra={UserPermission[routeId] !== 2 ? [] : [
                            <Button key="更新駕照效期" onClick={() => setModalStatus("2")} icon={<CalendarOutlined />}>更新駕照效期</Button>,
                            <Button key="任職狀態設定" onClick={() => status === 0 ? setModalStatus("3b") : setModalStatus("3a")} icon={<PauseCircleOutlined />}>任職狀態設定</Button>
                        ]}
                        breadcrumb={<BreadCrumb Info={{ [pointId]: state.point, [technicianId]: data.name }} />}
                    >
                    </PageHeader>
                    <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", }}>
                        <div className='content_block' style={{ padding: 24 }}>
                            <Card
                                style={{ width: "100%" }}
                                title="基本資料"
                                extra={
                                    UserPermission[routeId] !== 2 ? false :
                                        <Button
                                            type="text"
                                            style={{ paddingRight: 0 }}
                                            onClick={() => {
                                                setModalStatus("1")
                                                // console.log(data)
                                                setTimeout(() => {
                                                   
                                                    editForm.current.setFieldsValue(data)
                                                }, 200)
                                            }}
                                        > 修改
                                            <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                        </Button>
                                }
                            >
                                <CustomCard
                                    header={listHeader} data={data} />
                            </Card>

                            <Card title="生理監控" bordered={false}
                                style={{ marginTop: 24 }}
                                extra={
                                    <Button
                                        type="text"
                                        style={{ paddingRight: 0 }}
                                        onClick={() => navigate(`/points/${pointId}/${technicianId}/health`, { state: { point: state.point, driver: data.name } })}
                                    > 查看更多
                                        <RightOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                    </Button>
                                }
                            >
                                <Row
                                    style={{ display: "grid", gridTemplateColumns: "repeat(3,1fr)" }}
                                >
                                    <Statistic title={'當前心律（次/分）'} value={data.heart_rate} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.03)', paddingRight: 24, margin: "0 auto" }} />
                                    <Statistic title={'當前血氧（%）'} value={data.blood_oxygen} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.03)', paddingRight: 24, margin: "0 auto" }} />
                                    <Statistic title={'當前疲勞度（%）'} value={data.fatigue} style={{ borderRight: '', paddingRight: 24, margin: "0 auto" }} />
                                </Row>
                            </Card>
                        </div>
                        <HistoryRecord detail={data} History={history} />
                    </div>
                    <Drawer
                        title="編輯 - 基本資料"
                        placement="right"
                        size={"large"}
                        closable={false}
                        width={1200}
                        extra={
                            <CloseOutlined style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }} onClick={() => onClose()} />
                        }
                        footer={
                            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                <Button type='text' onClick={() => onClose()}>取消</Button>
                                <Button style={{ marginLeft: 8 }} onClick={() => editForm.current.submit()}>編輯</Button>
                            </div>
                        }
                        visible={modalStatus === "1" ? true : false}>
                        <Form
                            name="basic"
                            layout="vertical"
                            ref={editForm}
                            onFinish={editOnFinish}
                            autoComplete="off"
                            style={{ width: "100%" }}
                        >
                            <div style={{ height: "100%" }}>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="姓名"
                                        name="name"
                                        rules={[{ required: true, message: '請輸入姓名!' }]}
                                    >
                                        <Input allowClear placeholder='點擊選擇' style={{ width: "304px" }} />
                                    </Form.Item>

                                    <Form.Item
                                        label="身分證號"
                                        name="id_no"
                                        rules={[{ required: true, message: '請輸入身分證號！' },
                                        {
                                            pattern: /^[A-Z]{1}[1-2]{1}[0-9]{8}$/,
                                            message: "身分證號格式錯誤",
                                        }]}
                                        style={{ margin: "0 16px" }}
                                    >
                                        <Input allowClear placeholder='example' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label=""
                                        name="id_picture"
                                    >
                                        <div style={{ display: "flex", margin: "0" }}>
                                            <Upload
                                                multiple={true}
                                                fileList={IdPic}
                                                maxCount={2}
                                                customRequest={({ onSuccess }) => onSuccess("ok")}
                                                onChange={({ file, fileList }) => {
                                                    setIdPic(fileList)
                                                    editForm.current.setFieldsValue({ id_picture: fileList })
                                                }}
                                            >
                                                <div style={{ height: 34, width: 24 }}></div>
                                                <Button icon={<UploadOutlined />}>上傳身分證正反面</Button>
                                            </Upload>
                                        </div>
                                    </Form.Item>

                                </div>
                                <div style={{ display: "flex" }}>
                                    <Form.Item
                                        label="出生日期"
                                        name="bod"
                                        rules={[{ required: true, message: '請輸入姓名!' }]}

                                    >
                                        <DatePicker style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="到職日"
                                        name="workDate"
                                        rules={[{ required: true, message: '請選擇到職日！' }]}
                                        style={{ margin: '0 0 0 16px' }}
                                    >
                                        <DatePicker style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="駕照效期"
                                        name="drivingLicenseDate"
                                        rules={[{ required: true, message: '請選擇駕照效期！' }]}
                                        style={{ margin: '0 16px' }}
                                    >
                                        <DatePicker style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label=""
                                        name="driving_license"
                                    >
                                        <div style={{ display: "flex", margin: "0" }}>
                                            <Upload
                                                multiple={true}
                                                fileList={licensePic}
                                                maxCount={2}
                                                customRequest={({ onSuccess }) => onSuccess("ok")}
                                                onChange={({ file, fileList }) => {
                                                    setLicensePic(fileList)
                                                    editForm.current.setFieldsValue({ driving_license: fileList })
                                                }}
                                            >
                                                <div style={{ height: 34, width: 24 }}></div>
                                                <Button icon={<UploadOutlined />}>上傳駕照正反面</Button>
                                            </Upload>
                                        </div>
                                    </Form.Item>


                                </div>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <Form.Item
                                        label="手機號碼"
                                        name="mobile_phone_number"
                                        rules={[{ required: true, message: '請輸入手機號碼!' }, {
                                            pattern: /^[\d]{10}$/,
                                            message: "請輸入10位數之手機號碼",
                                        }]}
                                        hasFeedback={isValid !== null ? true : false}
                                        validateStatus={isValid === null ? '' : isValid ? 'success' : 'error'}
                                    >
                                        <Input 
                                        allowClear placeholder='example'
                                         style={{ width: "304px" }} 
                                         addonAfter={<EnterOutlined style={{ cursor: "pointer" }} onClick={(e) => checkMobile()} />}
                                         />
                                    </Form.Item>
                                    <Form.Item
                                        label="聯絡地址"
                                        name="address_code"
                                        rules={[{ required: true, message: "請選擇地址!" }]}
                                        style={{ marginLeft: 16, marginRight: 16 }}
                                    >
                                        <CustomCascader
                                            defaultValue={data ? data.address_code : ''}
                                            style={{ width: "304px" }}
                                            OnChange={(val) => editForm.current.setFieldsValue({ address_code: val })}
                                        />
                                    </Form.Item>
                                    <div>
                                        <div style={{ height: 30 }}></div>
                                        <Form.Item
                                            label=""
                                            name="address"
                                            rules={[{ required: true, message: "請輸入完整地址!" }]}
                                            style={{ marginRight: "16px" }}
                                        >
                                            <Input allowClear placeholder='請輸入完整地址' style={{ width: "304px" }} />
                                        </Form.Item>

                                    </div>

                                </div>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <Form.Item
                                        label="緊急聯絡人 - 1"
                                        name="emergency_contact1"
                                        rules={[{ required: true, message: '請輸入緊急聯絡人名字!' }]}
                                    >
                                        <Input prefix={<UserOutlined />} allowClear placeholder='請輸入名字' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <div>
                                        <div style={{ height: 30 }}></div>
                                        <Form.Item
                                        label=""
                                        name="emergency_contact1_tel"
                                        rules={[{ required: true, message: '請輸入緊急聯絡人電話' }]}
                                        style={{ margin: '0 16px 24px' }}
                                    >
                                        <Input prefix={<PhoneOutlined />} allowClear placeholder='請輸入電話' style={{ width: "304px" }} />
                                    </Form.Item>
                                    </div>
                                   

                                </div>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <Form.Item
                                        label="緊急聯絡人  - 2"
                                        name="emergency_contact2"
                                    >
                                        <Input prefix={<UserOutlined />} allowClear placeholder='請輸入名字' style={{ width: "304px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label=""
                                        name="emergency_contact2_tel"
                                        // rules={[{ required: true, message: '請輸入緊急聯絡人電話' }]}
                                        style={{ margin: '0 16px 24px' }}
                                    >
                                        <Input prefix={<PhoneOutlined />} allowClear placeholder='請輸入電話' style={{ width: "304px" }} />
                                    </Form.Item>

                                </div>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <Form.Item
                                        label="服務技能（可複選）"
                                        name="Skill"
                                        rules={[{ required: true, message: '請選擇服務技能（可複選）!' }]}
                                        style={{ marginRight: 16 }}
                                    >
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{ width: 304 }}
                                            placeholder="點擊選擇"
                                            options={carTypeList ? carTypeList : []}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="出勤型態"
                                        name="working_type"
                                        rules={[{ required: true, message: '請選擇出勤型態！' }]}
                                    >
                                        <Radio.Group buttonStyle="solid" onChange={(e) => {
                                            setWorkingType(e.target.value)
                                        }}>
                                            <Radio.Button value="1">正式</Radio.Button>
                                            <Radio.Button value="2">支援</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label="接案類型"
                                        name="task_type"
                                        rules={[{ required: true, message: '請選擇接案類型！' }]}
                                        style={{ margin: '0 16px 24px' }}
                                    >
                                        <Radio.Group buttonStyle="solid">
                                            <Radio.Button value="1">預約</Radio.Button>
                                            <Radio.Button value="2">即時</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>

                                {
                                    WorkingType === "1" && (
                                        <>
                                            <Form.Item
                                                label="常用車"
                                                name="plate_number"
                                                rules={[{ required: true, message: '請選擇常用車！' }]}
                                            >
                                                <Select
                                                    placeholder='點擊選擇'
                                                    style={{ width: 304 }}
                                                >
                                                        <option value="-">不指定</option>
                                                    {
                                                        data['workStatus']['content'][0] ==='在職' &&(
                                                            UnoccupiedCarList.map(car => (<option key={car.row_id} value={car.no}>{car.no}</option>))
                                                        )
                                                    }

                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label='上班日'
                                                name='work'
                                                rules={[{ required: true, message: '請選擇上班日！' }]}
                                            >
                                                <ul className='custom_radio'>
                                                    {
                                                        work.map(w => (
                                                            <li
                                                                className={workSelected[w.key] ? 'active' : ''}
                                                                onClick={() => {
                                                                    let selected = { ...workSelected }
                                                                    if (selected[w.key]) {
                                                                        delete selected[w.key]
                                                                    } else {
                                                                        selected[w.key] = true
                                                                    }
                                                                    setWorkSelected(selected)
                                                                    // let val = ''
                                                                    // if (Object.keys(selected).length > 0) {
                                                                    //     val = selected
                                                                    // } else {
                                                                    //     val = ''
                                                                    // }
                                                                    // console.log(val)
                                                                    // editForm.current.setFieldsValue({ work: val })
                                                                }}
                                                                key={w.title}>{w.day}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </Form.Item>
                                        </>
                                    )
                                }
                            </div>

                        </Form>
                    </Drawer>
                </>) : (<div style={{ padding: 24, height: '100%' }}>
                    <LoadingBlock />
                </div>)
            }



            <Modal
                title={
                    modalStatus === "2" ? "請輸入新的駕照效期並更新駕照" :
                        modalStatus === "3a" ? "請輸入離職時間" : "請輸入復職時間"}
                visible={modalStatus !== "1" && modalStatus !== null}
                width={416}
                onOk={() => settingForm.current.submit()}
                onCancel={() => onClose()}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    ref={settingForm}
                    onFinish={settingOnSubmit}
                >
                    {
                        modalStatus && modalStatus.indexOf("3") > -1 && (<div style={{ color: "rgba(0, 0, 0, 0.45)", marginBottom: 8 }}>{modalStatus === '3a' ? '離職會在您設定的日期當晚跨日後生效' : '復職會在您設定的日期當天零時生效'}</div>)
                    }
                    <Form.Item
                        label=""
                        name="date"
                        rules={[{ required: true, message: '請選擇有效期限！' }]}

                    >
                        <DatePicker placeholder='有效期限' style={{ width: "100%" }} />
                    </Form.Item>
                    {
                        modalStatus === "2" && (
                            <Form.Item
                                label=""
                                name='driving_license'
                                rules={[{ required: true, message: '上傳駕照正反面！' }]}
                            >
                                <Upload
                                    accept="image/*"
                                    maxCount={2}
                                    customRequest={({ onSuccess }) => onSuccess("ok")}
                                >
                                    <Button icon={<UploadOutlined />}>上傳駕照正反面</Button>
                                </Upload>
                            </Form.Item>
                        )
                    }
                    {
                        modalStatus === "3a" && (
                            <Form.Item
                                label=""
                                name="leave_office_reason"
                                rules={[{ required: true, message: '請簡述離職原因' }]}
                            >
                                <Input.TextArea style={{ width: '100%' }} placeholder="請簡述離職原因" />
                            </Form.Item>
                        )
                    }
                </Form>
            </Modal>
        </div>
    );
}

export default Techniciandetail;
