import React, { useState, useEffect, useRef } from 'react'
import BreadCrumb from '../../../components/shared/BreadCrumb'
import CustomTable from '../../../components/shared/component/Card'
import { PageHeader, Tabs, Button, Card, Table, Tag, Modal, Input, Popconfirm, message, Badge, Drawer, Form, Radio, Select } from 'antd'
import { KeyOutlined, PauseCircleOutlined, EditOutlined, PlusOutlined, CloseOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { GetCustomerDetail, UpdateCustomerDetail, AddCustomerRemark, GetCustomerEmployeeList, GetCustomerPricingModule, GetCustomerHistory, UpdateCustomerStatus } from '../../../api/Customer'
import { GetStationList } from '../../../api/Station'
import { GetCustomerType } from '../../../api/Code'
import HistoryRecord from '../../../components/shared/component/HistoryRecord'
import { UpdatePassowrdByUserId } from '../../../api/Member'
import CustomCascader from '../../../components/shared/component/CustomCascader'
// import { FilterConty } from '../../../components/shared/util/FilterFunc'
import { useSelector } from 'react-redux'
import LoadingBlock from '../../../components/shared/LoadingBlock'
import { RouteName } from '../../../util/route'
import moment from 'moment'
const { TabPane } = Tabs
const { Option } = Select
const { TextArea } = Input

const CustomerDetail = ({ ContainerWidth, pageTitle }) => {
    let { customerId, employeeId } = useParams()
    const { state, pathname } = useLocation()
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo)
    let UserPermission = UserInfo ? UserInfo.permission.route : null
    let Town = useSelector(state => state.county)
    let CaseType = useSelector(state => state.caseType)
    let PriceModule = useSelector(state => state.PriceModule)
    let CurrentTab = state && state.tab ? state.tab : "1"
    const navigate = useNavigate()
    const [pagination, setPagination] = useState(state?.pagination ? state.pagination : { current: 1, pageSize: 10 })
    const [editDrawer, setEditDrawer] = useState(false);
    const [editRemark, setEditRemark] = useState(null)
    const [remarkContent, setRemarkContent] = useState(null)
    const [DetailEditData, setDetailEditData] = useState(null)
    const [history, setHistory] = useState(null)
    const [AllPricingModuleList, setAllPricingModuleList] = useState(null)
    const editSubmitRef = useRef()
    const resetPasswordForm = useRef()
    const [passwordError, setPasswordError] = useState(null)
    const columnsType = {
        2: [
            { title: '中文姓名', dataIndex: 'name_cht' },
            { title: '職稱', dataIndex: 'job_title' },
            { title: '聯絡電話', dataIndex: 'tel' },
            { title: 'E-mail', dataIndex: 'email' },
            {
                title: '任職狀態', dataIndex: 'status', width: '10%',
                render: status => (<div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: 6, height: 6, borderRadius: "50%", background: status === 1 ? "rgba(82, 196, 26, 1)" : "rgba(217, 217, 217, 1)", marginRight: 8 }}></div>
                    <p> {status === 1 ? "在職" : "離職"}</p></div>)
            },
            { title: '身份標籤', dataIndex: 'role', render: label => label.length === 0 ? '-' : label.map(l => (<Tag>{l}</Tag>)) }],
        3: [
            { title: '代號', dataIndex: 'user_id' },
            { title: '名稱', dataIndex: 'name' },
            { title: '公司地址', dataIndex: 'full_add' },
            { title: '聯絡電話', dataIndex: 'tel' },
            {
                title: '配合狀態', dataIndex: 'status', render: status => (<div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: 6, height: 6, borderRadius: "50%", background: status === 1 ? "rgba(82, 196, 26, 1)" : "rgba(217, 217, 217, 1)", marginRight: 8 }}></div>
                    <p> {status === 1 ? "配合" : "取消"}</p></div>)
            },
            {
                title: '營業狀態', dataIndex: 'operatingStatus', render: status => (<div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: 6, height: 6, borderRadius: "50%", background: status ? "rgba(82, 196, 26, 1)" : "rgba(217, 217, 217, 1)", marginRight: 8 }}></div>
                    <p> {status ? "營業中" : "已打烊"}</p></div>)
            },
        ],
        4: [
            { title: '專案編號', dataIndex: 'id' },
            { title: '合約名稱', dataIndex: 'name' },
            { title: '服務案型', dataIndex: 'case_type_text' },
            { title: '計價模式', dataIndex: 'mode_text' },
            { title: '生效期間', dataIndex: 'validDate' },
            {
                title: '方案狀態', dataIndex: 'status', render: (status, record) => (<div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: 6, height: 6, borderRadius: "50%", background: status === 1 ? "rgba(82, 196, 26, 1)" : status === 2 ? "#FAAD14" : status === 0 ? "#FF4D4F" : "#FF4D4F", marginRight: 8 }}></div>
                    <p> {record.status_text}</p>
                </div>)
            }
        ]
    }


    const [currentTab, setCurrentTab] = useState(CurrentTab)
    const [statusTab, setStatusTab] = useState(1)
    const [modalStatus, setModalStatus] = useState(false)
    const [popStatus, setPopStatus] = useState(false)
    const [fetchData, setFetchData] = useState(null)
    const [EmployeeList, setEmployeeList] = useState(null)
    const [customerType, setCustomerType] = useState(null)
    const [TableListData, setTableListData] = useState(null)
    const [cooperationStatus, setCooperationStatus] = useState(null)
    const regions = {"1":"北區","2":'中區',"3":"全業"}
    const [tabs, setTabs] = useState([
        { id: "tab1", name: "啟用", count: null, status: 1 },
        { id: "tab3", name: "已過期", count: null, status: 3 },
        { id: "tab2", name: "尚未開始", count: null, status: 2 },
        { id: "tab4", name: "停用", count: null, status: 0 },
    ])
    const ChangeCoperationStatus = async () => {
        let status = cooperationStatus === "0" ? "1" : "0"
        let param = `row_id=${fetchData.row_id}&status=${status}`
        let res = await UpdateCustomerStatus(param)
        if (res.Result.Success) {
            setPopStatus(true)
            message.success("配合狀態已變更")
            fetchDetail()
        }
    }
    const functionBtn = UserPermission && UserPermission[routeId] !== 2 ? [] : currentTab === "1" && cooperationStatus === '0' ?
        [<Button key={"變更密碼"} icon={<KeyOutlined />} onClick={() => setModalStatus(true)}>變更密碼</Button>, <Popconfirm
            placement="bottom"
            title={"復原後將可再次作為運送點"}
            // onCancel={ ()=> setPopStatus(false)} 
            onConfirm={() => { ChangeCoperationStatus() }}
            style={{ marginRight: 12 }}
            okText="重啟配合"
            showCancel={false}
        >
            <Button key={"2"} icon={<PlayCircleOutlined />} type="primary">重新啟用</Button>
        </Popconfirm>] : currentTab === "1" && cooperationStatus === '1' ?
            [<Button key={"變更密碼"} icon={<KeyOutlined />} onClick={() => setModalStatus(true)}>變更密碼</Button>, <Popconfirm
                placement="bottom"
                title={"取消配合後，將無法上單"}
                // onCancel={ ()=> setPopStatus(false)} 
                onConfirm={() => { ChangeCoperationStatus() }}
                style={{ marginRight: 12 }}
                okText="好，取消配合"
                showCancel={false}
            >
                <Button key={"變更密碼"} icon={<PauseCircleOutlined />}>配合狀態變更</Button>
            </Popconfirm>] : currentTab === "2" ?
                [<Button key={"新增人員"} type="primary" icon={<PlusOutlined />}
                    onClick={() => navigate(`/customer/lists/${customerId}/add`, {
                        state: {
                            tab: currentTab,
                            page: pagination.current,
                            customerId: customerId,
                            BreadcrumbInfo: { [customerId]: fetchData.name },
                            pagination
                        }
                    })}>
                    新增人員</Button>] : currentTab === "3" ? [
                        <Button key={"新增運送點"} type="primary" icon={<PlusOutlined />} onClick={() => navigate(`/customer/lists/${customerId}/add`, {
                            state: {
                                tab: currentTab,
                                statusTab: statusTab,
                                page: pagination.current,
                                customerId: customerId,
                                BreadcrumbInfo: { [customerId]: fetchData.name }, pagination

                            }
                        })}>新增運送點</Button>
                    ] : [<Button key={"新增計價模組"} type="primary" icon={<PlusOutlined />} onClick={() => navigate(`/customer/lists/${customerId}/add`, {
                        state: {
                            tab: currentTab,
                            statusTab: statusTab,
                            page: pagination.current,
                            customerId: customerId,
                            BreadcrumbInfo: { [customerId]: fetchData.name },
                            customer_id: fetchData.id,
                            customer_name: fetchData.name,
                            pagination
                        }
                    })}>新增計價模組</Button>]

    const fetchDetail = async () => {
        //基本資訊
        let customer = customerType
        if (!customer) {
            let res = await GetCustomerType()
            if (res !== null) {
                setCustomerType(res.Result)
                customer = res.Result
            }
        }
        let res = await GetCustomerDetail(customerId)
        if (!res.Error) {
            let result = res.Result;
            result.companyAddress = `${result.county_name}${result.town_name}${result.address}`
            result.corpertationStatus = { type: "status", color: result.status === 1 ? '#52C41A' : '#D9D9D9', content: result.status === 1 ? ['配合中'] : ["取消"] }
            result.customerLevel = result.is_VIP ? "VIP " : '一般'
            result.status = result.status.toString()
            // result.customer_type = result.customer_type && result.customer_type.indexOf(',') > -1 ? result.customer_type.split(",") : result.customer_type !== "" ? [result.customer_type] : []
            let customer_type = []
            if (result.customer_type) {
                customer_type = result.customer_type.indexOf(",") > -1 ? result.customer_type.split(",") : [result.customer_type]
            }
            let customerTypeText = []
            let address_code = []
            if (result.town && result.county) {
                address_code = [result.county, result.town]
            }
            result.address_code = address_code
            result.customer_type = customer_type
            if (customer && result.customer_type.length > 0) {
                customerTypeText = customer_type.map(c => {
                    let filter = customer.filter(type => type.Code === c)
                    if (filter.length > 0) {
                        return filter[0]['Name']
                    } else {
                        return ""
                    }
                })
            }

            result.customerType = { type: "tag", content: result.customer_type ? customerTypeText : customerTypeText }
            result.remark = { type: "remark", content: result.note }
            result.business_contact_name = result.business_contact
            result.execution_region = regions[result.execution_region_type] || '-'
            setFetchData(result)
            setCooperationStatus(result.status)
            let history = await GetCustomerHistory(customerId, 0, -1)
            if (!history.Error) {
                setHistory(history.Result.Data.reverse())
            }
        }
    }
    const fetchAPI = async () => {
        let obj = null
        if (currentTab === "2") {
            //人員管理
            let EmployeeRes = await GetCustomerEmployeeList(customerId, 0, -1)
            if (!EmployeeRes.Error) {
                let EmployeeList = EmployeeRes.Result
                EmployeeList.Data.map(d => {
                    let role = []
                    if (d.role_keyin === 1) role.push("可上單")
                    if (d.role_payment === 1) role.push("請款窗口")
                    d.role = role
                    return d
                })
                obj = { 2: EmployeeList }
            }
        } else if (currentTab === "3") {
            //運送點管理
            let StationRes = await GetStationList(customerId, 0)
            let station_data = []
            if (!StationRes.Error) {
                let StationList = StationRes.Result
                let PageCount = StationList.PageCount
                let todayDay = moment().day()
                let openVariable = (todayDay === 6 || todayDay === 0) ? 'weekend_open' : 'normal_open'
                let variable = (todayDay === 6 || todayDay === 0) ? 'weekend' : 'normal'
                for (let i = 0; i < PageCount; i++) {
                    let Res = await GetStationList(customerId, i)
                    if (Res && !Res.Error) {
                        let Data = Res["Result"]["Data"]
                        Data.forEach(d => {
                            d.full_add = `${d.county_name ? d.county_name : ""}${d.town_name ? d.town_name : ""}${d.address}`
                            let operated = false;
                            if (d[openVariable]) {
                                let isOperated = moment(moment(), "HH:mm").isBetween(moment(d[`${variable}_start_time`], "HH:mm"), moment(d[`${variable}_end_time`], "HH:mm"))
                                operated = isOperated
                            }
                            d.operatingStatus = operated
                            station_data.push(d)
                            // return d
                        })
                    }
                }
                StationList.Data = station_data
                obj = { 3: StationList }
            }
        } else if (currentTab === "4") {
            //計價模組 / 合約
            let PricingModuleList = await GetCustomerPricingModule(customerId, "00", 0, -1)
            if (!PricingModuleList.Error) {
                let PriceList = PricingModuleList.Result
                let tab_obj = { 0: 0, 1: 0, 2: 0, 3: 0 }
                let res = PriceList.Data
                res.forEach(r => {
                    tab_obj[r.status] += 1
                })
                let new_tabs = [...tabs].map(t => {
                    t.count = tab_obj[t.status]
                    return t
                })
                setTabs(new_tabs)
                if (CaseType !== null && PriceModule) {
                    PriceList.Data = PriceList.Data
                        .map(p => {
                            let filter = CaseType.filter(c => c.Code === p.case_type)
                            if (filter.length > 0) {
                                p.case_type_text = `${filter[0]["Name"]}`
                                // p.case_type_text = `${filter[0]["Code"]}:${filter[0]["Name"]}`
                            }
                            p.validDate = `${moment(p.start_date).format('YYYY.MM.DD')}-${moment(p.end_date).format('YYYY.MM.DD')}`
                            p.mode_text = PriceModule[p.mode]
                            return p
                        })
                    setAllPricingModuleList(PriceList)
                    let new_price_list = PriceList.Data.filter(p => p.status === statusTab)
                    let new_price = { ...PriceList }
                    new_price.Data = new_price_list
                    obj = { 4: new_price }

                }

            }
        }
        setTableListData(obj)

    }

    useEffect(() => {
        fetchDetail()
        fetchAPI()

    }, [])
    useEffect(() => {
        fetchAPI()
        setTimeout(() => {
            editSubmitRef.current?.setFieldsValue(fetchData)
        }, 500)
    }, [DetailEditData, remarkContent, editDrawer, PriceModule, statusTab, currentTab, pagination])
    return (
        <div className='container' style={ContainerWidth}>
            <div style={{ height: "calc(100%)" }}>
                {
                    fetchData ? (<>
                        <PageHeader
                            style={{ background: "white", width: "100%" }}
                            title={fetchData ? `${fetchData.id} - ${fetchData.name}` : ''}
                            onBack={() => navigate("/customer/lists/", { state: state })}
                            className="site-page-header"
                            breadcrumb={<BreadCrumb
                                // CustomerId={{ [customerId]: customerId }} 
                                Info={fetchData !== null && { [customerId]: fetchData.name }}
                            />}
                            extra={functionBtn}

                            footer={
                                <Tabs defaultActiveKey={currentTab} onTabClick={(value) => setCurrentTab(value)} >
                                    <TabPane tab={"基本資訊"} key="1" />
                                    <TabPane tab={"人員管理"} key="2" />
                                    <TabPane tab={"運送點管理"} key="3" />
                                    <TabPane tab={"計價模組 / 合約"} key="4" />
                                </Tabs>
                            }
                        >
                        </PageHeader>
                        {
                            currentTab === "1" ? (
                                <div className='split_block' style={{ display: 'grid', gridTemplateColumns: "repeat(2, 1fr)", height: "calc(100% - 102px)" }}>
                                    {
                                        fetchData !== null ? (
                                            <div className='content_block' style={{ padding: 24 }}>

                                                <Card
                                                    style={{ width: "100%", marginBottom: 24 }}
                                                    title="基本資訊"
                                                    extra={UserPermission && UserPermission[routeId] !== 2 ? false :
                                                        <Button
                                                            type="text"
                                                            style={{ paddingRight: 0 }}
                                                            onClick={() => {
                                                                setEditDrawer(true)

                                                            }
                                                            }
                                                        >
                                                            修改
                                                            <EditOutlined style={{ color: "rgba(24, 144, 255, 1)" }} />
                                                        </Button>
                                                    }
                                                >
                                                    <CustomTable
                                                        remarkStatus={(value => setEditRemark(value))}
                                                        header={[
                                                            {
                                                                key: "id",
                                                                name: "客戶代號"
                                                            },
                                                            {
                                                                key: "name",
                                                                name: "客戶名稱"
                                                            },
                                                            {
                                                                key: "tax_id_no",
                                                                name: "公司統編"
                                                            },
                                                            {
                                                                key: "companyAddress",
                                                                name: "公司地址"
                                                            },
                                                            {
                                                                key: "customerType",
                                                                name: "客戶類別"
                                                            },
                                                            {
                                                                key: "corpertationStatus",
                                                                name: "配合狀態",
                                                            },
                                                            {
                                                                key: "business_contact_name",
                                                                name: "業務窗口姓名",
                                                            },
                                                            {
                                                                key: "customerLevel",
                                                                name: "客戶等級"
                                                            },
                                                            {
                                                                key: "execution_region",
                                                                name: "執行分區"
                                                            },                                                           
                                                            {
                                                                key: "remark",
                                                                name: "備註"
                                                            }


                                                        ]} data={fetchData} />

                                                </Card>
                                                <Card
                                                    style={{ width: "100%", marginBottom: 24 }}
                                                    title="帳號資訊"
                                                >
                                                    <CustomTable
                                                        header={[
                                                            {
                                                                key: "user_id",
                                                                name: "帳號"
                                                            },
                                                            {
                                                                key: "password",
                                                                name: "密碼"
                                                            }
                                                        ]}
                                                        data={fetchData} />
                                                </Card>
                                            </div>
                                        ) : (<div style={{ padding: 24, height: '100%' }}>
                                            <LoadingBlock />
                                        </div>)
                                    }
                                    <HistoryRecord detail={fetchData} History={history} />

                                </div>) : TableListData && TableListData[currentTab] ? (
                                    <div className='table_block' style={{ margin: 24, background: currentTab === "4" ? "white" : "", padding: currentTab === "4" ? "0 24px 24px" : "" }}>
                                        {
                                            currentTab === "4" && (<Tabs defaultActiveKey={statusTab}>
                                                {tabs.map((t) => (
                                                    <TabPane
                                                        key={t.id}
                                                        tab={
                                                            <div onClick={(e) => setStatusTab(t.status)}>
                                                                <span>{t.name}</span>
                                                                <Badge
                                                                    className="site-badge-count-109"
                                                                    overflowCount={999}
                                                                    count={t.count}
                                                                    style={{
                                                                        backgroundColor:
                                                                            statusTab === t.status ? "#E6F7FF" : "#F0F0F0",
                                                                        color:
                                                                            statusTab === t.status ? "#1890FF" : "rgba(0,0,0,0.45)",
                                                                        marginLeft: "4px",
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                    />
                                                ))}
                                            </Tabs>)
                                        }
                                        <Table
                                            columns={columnsType[currentTab]}
                                            rowKey={record => record.row_id}
                                            dataSource={TableListData[currentTab]["Data"]}
                                            pagination={TableListData[currentTab]["Data"].length > 10 ? {
                                                current: pagination.current,
                                                total: pagination.total,
                                                showTotal: (total, range) =>
                                                    `${range[0]}-${range[1]} 列（共${total}  列）`,
                                            } : false}
                                            // pagination={false}

                                            onChange={(pagination) => setPagination(pagination)}
                                            style={{ minHeight: 400, paddingBottom: 24 }}
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: event => {
                                                        navigate(`/customer/lists/${customerId}/details`,
                                                            {
                                                                state: {
                                                                    tab: currentTab,
                                                                    page: pagination.current,
                                                                    customerId: customerId,
                                                                    id: record.row_id,
                                                                    BreadcrumbInfo: { [customerId]: fetchData.name },
                                                                    customer_id: fetchData.id,
                                                                    customer_name: fetchData.name,
                                                                    tax_id_no: fetchData.tax_id_no,
                                                                    pagination
                                                                }
                                                            }
                                                        )
                                                    },
                                                };
                                            }}
                                        />
                                    </div>
                                ) : (<div style={{ padding: 24, height: '100%' }}>
                                    <LoadingBlock />
                                </div>)
                        }
                    </>) : (<div style={{ padding: 24, height: '100%' }}>
                        <LoadingBlock />
                    </div>)
                }


                <Modal title="變更密碼"
                    visible={modalStatus}
                    width={416}
                    onOk={() => {
                        resetPasswordForm.current.submit()
                        // setIsModalVisible("1a")
                    }}
                    onCancel={() => {
                        resetPasswordForm.current.resetFields()
                        setModalStatus(false)
                    }}
                >
                    <Form
                        ref={resetPasswordForm}
                        name="basic"
                        layout='vertical'
                        onFinish={(value) => {
                            if (value.password !== value.comfirm_password) {
                                setPasswordError(true)
                            } else {
                                value.user_id = fetchData.user_id
                                UpdatePassowrdByUserId(value).then(res => {
                                    if (!res.Error) {
                                        fetchDetail()
                                        setPasswordError(null)
                                        setModalStatus(false)
                                        resetPasswordForm.current.resetFields()
                                    }
                                })
                            }
                        }}
                    >
                        <Form.Item
                            label=""
                            name="password"
                            validateStatus={passwordError ? "error" : ""}
                            rules={[{ required: true, message: "請輸入新密碼!" }]}
                        >
                            <Input.Password
                                allowClear
                                placeholder="請輸入新密碼"
                            />
                        </Form.Item>
                        <Form.Item
                            label=""
                            name="comfirm_password"
                            rules={[{ required: true, message: "再次輸入新密碼!" }]}
                            validateStatus={passwordError ? "error" : ""}
                            help={passwordError ? "新密碼與再次輸入新密碼不一致" : ""}
                        >
                            <Input.Password
                                allowClear
                                placeholder="再次輸入新密碼"
                            />
                        </Form.Item>
                    </Form>

                </Modal>

                {
                    fetchData && (<Drawer
                        title="編輯 - 基本資訊"
                        placement="right"
                        closable={false}
                        extra={<CloseOutlined
                            style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.45)" }}
                            onClick={() => setEditDrawer(false)}
                        />}
                        visible={editDrawer}
                        footer={
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button type="text" style={{ marginRight: 16 }} onClick={() => {
                                    setEditDrawer(false)
                                }
                                }>取消</Button>
                                <Button type="primary"
                                    // disabled={btnDisabled}
                                    onClick={() => editSubmitRef.current.submit()}>保存</Button>
                            </div>
                        }
                    >
                        <Form
                            ref={editSubmitRef}
                            name="basic"
                            layout='vertical'
                            initialValues={fetchData}
                            onFinish={(value) => {
                                // if (DetailEditData) {
                                //     Object.keys(DetailEditData).forEach(data => {
                                //         value[data] = DetailEditData[data]
                                //     })
                                // }
                                value.row_id = fetchData.row_id
                                value.is_VIP = value.status
                                value.county = value.address_code[0]
                                value.town = value.address_code[1]
                                let customer_type = ''
                                if (value.customer_type.length > 0) {
                                    value.customer_type.forEach((type, i) => {
                                        customer_type += i === 0 ? type : `,${type}`
                                    })
                                }
                                value.type_code = customer_type
                                let param = ''
                                Object.keys(value).forEach((key, i) => {
                                    param += i === 0 ? `${key}=${value[key]}` : `&${key}=${value[key]}`
                                })
                                UpdateCustomerDetail(param)
                                    .then(res => {
                                        if (res && !res.Error) {
                                            setDetailEditData(null)
                                            setEditDrawer(false)
                                            fetchDetail()
                                        }
                                    })
                            }}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="客戶名稱"
                                name="name"
                                rules={[{ required: true, message: "請輸入客戶名稱!" }]}
                            >
                                <Input
                                    allowClear
                                    placeholder="請輸入客戶名稱"
                                />
                            </Form.Item>
                            <Form.Item
                                label="公司統編"
                                name="tax_id_no"
                                rules={[
                                    { required: true, message: "請輸入公司統編!" },
                                    {
                                        pattern: /^[\d]{8}$/,
                                        message: "請輸入8位數之公司統編",
                                    }]}
                            >
                                <Input allowClear placeholder="請輸入公司統編" d />
                            </Form.Item>
                            <Form.Item
                                label="公司地址"
                                name="address_code"
                                rules={[{ required: true, message: "" }]}
                                style={{ marginBottom: 4 }}
                            >

                                <CustomCascader
                                    defaultValue={editSubmitRef.current?.getFieldValue().address_code}
                                    style={{ marginBottom: 6 }}
                                    OnChange={(val) => {
                                        editSubmitRef.current.setFieldsValue({ address_code: val })
                                    }}
                                />

                            </Form.Item>
                            <Form.Item
                                label=""
                                name="address"
                                rules={[{ required: true, message: "請輸入公司地址!" }]}
                            >

                                <Input
                                    allowClear
                                    placeholder="請輸入公司地址"
                                    onKeyUp={(e) => {
                                        // if (DetailEditData) {
                                        //     setDetailEditData({
                                        //         ...DetailEditData,
                                        //         address: e.target.value,
                                        //     })
                                        // } else {
                                        //     setDetailEditData({ address: e.target.value })
                                        // }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label="業務窗口姓名"
                                name="business_contact_name"
                            >
                                <Input allowClear placeholder='example' />
                            </Form.Item>
                            <Form.Item
                                label="客戶等級"
                                name="status"
                                rules={[{ required: true, message: "請輸入客戶等級!" }]}
                            >
                                <Radio.Group buttonStyle="solid" >
                                    <Radio.Button value="0">一般客戶</Radio.Button>
                                    <Radio.Button value="1">VIP 客戶</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="執行分區"
                                name="execution_region_type"
                                rules={[{ required: true, message: '請選擇執行分區！' }]}
                            >
                                <Radio.Group
                                    buttonStyle="solid"
                                // style={{ margin: "8px 0 24px 0" }}
                                >
                                {
                                    Object.keys(regions).map(r=>(<Radio.Button value={r}>{regions[r]}</Radio.Button>))
                                }
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label={<><span>客戶類別</span><span style={{ paddingLeft: 4, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>(可複選)</span></>}
                                name="customer_type"
                            >
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: "304px" }}
                                    placeholder="請選擇客戶類別"
                                >
                                    {
                                        customerType !== null && customerType.map(type => (<Option key={type.Code}>{type.Name}</Option>))
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    </Drawer>)
                }
            </div>
            {
                fetchData && (<Modal title="請輸入備註"
                    visible={editRemark === 1 ? true : false}
                    width={416}
                    onOk={() => {
                        let param = `row_id=${fetchData.row_id}&note=${remarkContent}`
                        AddCustomerRemark(param)
                            .then(res => {
                                if (res.Result.Success) {
                                    setEditRemark(null)
                                    setRemarkContent(null)
                                    fetchDetail()
                                }
                            })
                    }}
                    onCancel={() => setEditRemark(null)}
                >
                    <TextArea
                        defaultValue={fetchData.note}
                        placeholder="額外的備註請寫在此......"
                        style={{ width: 368 }}
                        onKeyUp={(e) => setRemarkContent(e.target.value)}
                    />
                </Modal>)
            }
        </div>
    );
}

export default CustomerDetail;