import EmployeeDetail from '../../../components/customers/customer/employeeDetail'
import DeliveryCenterDetail from '../../../components/customers/customer/deliveryCenterDetail'
import PricingModule from '../../../components/customers/customer/pricingModule'
import {useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RouteName } from '../../../util/route'

const Details = ({ ContainerWidth, pageTitle }) => {
    // let { customerId, employeeId } = useParams()
    const { state,pathname } = useLocation()
    const routeId = RouteName(pathname)
    let UserInfo = useSelector(state => state.UserInfo) 
    let UserPermission = UserInfo? UserInfo.permission.route: null
    //state.tab  2:客戶人員管理； 3:客戶人運送點管理； 4:客戶計價模組 / 合約
    console.log(state)
    return (<>
      <div className='container' style={ContainerWidth}>
            {
                state.tab === "2" && <EmployeeDetail propState={state} permission={UserPermission?UserPermission[routeId]:UserPermission}/>
            }
            {
                state.tab === "3" && <DeliveryCenterDetail propState={state} permission={UserPermission?UserPermission[routeId]:UserPermission}/>
            }
            {
                state.tab === "4" && <PricingModule propState={state} permission={UserPermission?UserPermission[routeId]:UserPermission}/>
            }
        </div>
    </>)
}

export default Details;